import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react'
import {reachGoal, YM_COUNTER_ID} from '../utils/metrics'

interface FeatureFlags {
  [key: string]: string
}

interface FeatureFlagContextProps {
  featureFlags: FeatureFlags
  flagsLoading: boolean
  isAB: (s: string) => boolean
}

const INITIAL_FLAGS: FeatureFlags = {
  add_instruments: 'true',
  premium_templates: 'false',
  credits_rename: 'false',
  buy_in_download_pop: 'true',
  watermark: 'true',
  packages_paywall: 'true',
  minimalism_default: 'false'
}

const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  featureFlags: INITIAL_FLAGS,
  flagsLoading: true,
  isAB: (name: string) => INITIAL_FLAGS[name] == 'true'
})

interface FeatureFlagProviderProps {
  children: ReactNode
}

const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(INITIAL_FLAGS)
  const [flagsLoading, setFlagsLoading] = useState<boolean>(true)

  const isAB = (name: keyof typeof INITIAL_FLAGS) => {
    return featureFlags[name] == 'true'
  }

  const fetchFlags = async () => {
    try {
      window.ymab(
          'metrika.' + YM_COUNTER_ID,
          'getFlags',
          (fetchedFlags: Record<string, string[]>) => {
            console.log('got flags')
            console.log(fetchedFlags)

            const newFlags: FeatureFlags = { ...INITIAL_FLAGS }
            for (const [key, value] of Object.entries(fetchedFlags)) {
              if (value !== undefined && value.length > 0) {
                newFlags[key] = value[0]
              }
            }
            setFeatureFlags(newFlags)
            setFlagsLoading(false)
          }
      )
    } catch (error) {
      console.error('Error fetching feature flags:', error)
      setFlagsLoading(false)
    }
  }

  useEffect(() => {
    fetchFlags()
  }, [])

  useEffect(() => {
    if(!flagsLoading) {
      reachGoal(isAB('minimalism_default') ? 'visit_group_test' : 'visit_group_control')
    }
  }, [flagsLoading])

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, flagsLoading, isAB }}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export { FeatureFlagProvider, FeatureFlagContext }
