import { ReactComponent as Loader } from './purple_loader.svg'
import cx from 'clsx'
import s from './LoadingModal.module.css'
import { useEffect, useState } from 'react'
export function LoadingModal({
  active,
  backgroundRemoved,
}: {
  active: boolean
  backgroundRemoved: boolean
}) {
  const [text, setText] = useState('')
  const texts = ['Определяем продукт', 'Пишем тексты', 'Творим волшебство']
  if (backgroundRemoved) {
    texts.unshift('Удаляем фон')
  }
  useEffect(() => {
    const changeRate = 1000
    setText(texts[0])
    let i = 1
    const int = setInterval(() => {
      setText(texts[i])
      i++
      if (!texts[i]) clearInterval(int)
    }, changeRate)
  }, [active])

  return active ? (
    <div className={s.Loading_outer}>
      <div className={s.Loading_inner}>
        <Loader className={s.Loading_loader} />
        <h2 className={cx('bodyL', s.Loading_text)}>{text}</h2>
      </div>
    </div>
  ) : null
}
