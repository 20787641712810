import { useAuthContext } from '../contexts/AuthProvider'
import { Outlet } from 'react-router-dom'
import { CompleteRegistrationPage } from './CompleteRegistrationPage'

export function CompletedRegistrationProtectedPage() {
  const { hasCompletedRegistration } = useAuthContext()

  if (!hasCompletedRegistration) {
    return <CompleteRegistrationPage />
  }

  return <Outlet />
}
