import {getProjectId} from "../../logger/logEvent";
import {getRandomAIServiceChoice} from "../../utils/ai-gen";
import {
    generateAiBackground as generateAiBackgroundAPI,
    generateAiBackgroundVariatively
} from "../../api/generateAiBackground";

export const getBackgroundGenerator = ({store, imageResultStore, setPosition, mainObject}: any) => async (
    blob: any,
    prompt: string,
    negativePrompt: string,
    options: { isSuggested?: boolean; translate?: boolean; selectAiService?: boolean } = {
        isSuggested: false,
        translate: false,
        selectAiService: false,
    }
) => {
    await store.waitLoading()
    const pid = getProjectId()
    const { isSuggested, selectAiService } = options
    const formData = new FormData()
    formData.append('file', blob, 'image.webp')
    formData.append('prompt', prompt)
    formData.append('negativePrompt', negativePrompt)
    formData.append('cardSize', store.custom.cardSize)
    if (options.translate) {
        formData.append('translate', options.translate.toString())
    }
    let srv = getRandomAIServiceChoice() // cobalt | photoroom

    let response = null

    if (isSuggested) {
        const bgRes = await generateAiBackgroundVariatively(formData, pid) // co | ph
        response = bgRes
        srv = bgRes.srv
    } else {
        const res = await generateAiBackgroundAPI(formData, pid, srv)
        response = res.data
    }

    if (!response || !response.imageUrl) {
        console.error('An error occurred:', response)
    }

    imageResultStore.storeUrl(response.imageUrl, srv)
    setPosition({
        x: mainObject.x,
        y: mainObject.y,
    })
    if (selectAiService) {
        imageResultStore.setCurrentByImageUrl(response.imageUrl)
    }
    return { imageUrl: response.imageUrl, meta: response.meta, srv }
}