import axios from 'axios'

import { backendUrl } from '../config/app'
import { Subscription } from '../store/useSubscriptions'

export const fetchActiveSubscriptions = async () => {
  const response = await axios.get(`${backendUrl()}api/ngb/subscriptions`)

  const sbs = response.data as Subscription[]

  return sbs
}
