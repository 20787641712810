import s from './NoSubscription.module.css'
import cx from 'clsx'
import { Button } from '../../Button'
import { ReactComponent as WarnIcon } from './WarnIcon.svg'
import {useNavigate} from "react-router-dom";
import {R_PLANS} from "../../../router-constants";

export function NoSubscription() {
  const navigate = useNavigate()

  const handleGetPlan = () => navigate(R_PLANS)

  return (
    <div className={s.Card}>
      <div className={s.Content}>
        <div>
          <WarnIcon />
        </div>
        <div className={cx('bodyM', s.Text)}>Вы еще не приобрели подписку</div>
      </div>

      <div className="Actions">
        <Button size="s" onClick={handleGetPlan}>
          Оформить тариф
        </Button>
      </div>
    </div>
  )
}
