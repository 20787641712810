import s from './modal.module.css'
import { useCredits } from '../../store/useCredits'
import Button, { ButtonType } from '../button/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { reachGoal } from '../../utils/metrics'
import { WriteTelegramBtn } from '../button/WriteTelegramBtn'
import { BackToEditBtn } from '../button/BackToEditBtn'
import boxStarImage from './box-star.png'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import cx from 'clsx'
import { pluralizeGenerations } from '../../utils/pluralizeGenerations'
import { useSubscriptions } from '../../store/useSubscriptions'
import { useEffect, useState } from 'react'

export const MODAL_NAME = 'successfullyPaid'
export const MODAL_NAME_SUBSCRIPTION = 'successfullySubscriptionPaid'

export function SuccessfullyPaidModal(props: SharedProps) {
  const { close } = props
  const credits = useCredits((s) => s.credits)

  const onSendMessageClick = () => {
    reachGoal('click_send_message')
    close()
  }

  return (
    <div>
      <img className={s.imgPopup} src={boxStarImage} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>Спасибо за покупку</h1>

      <div className={cx(s.creditsTextPopup, 'bodyM')}>
        {credits !== null && (
          <p>
            Остал{credits == 1 ? 'ась' : 'ось'} {credits} {pluralizeGenerations(credits)}
          </p>
        )}
      </div>

      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>Генерации были зачислены на ваш счет. Мы рады, что вы пользуетесь нашим приложением</p>

        <p>
          Если у вас есть вопросы или предложения, вы всегда можете связаться с нами через Telegram
        </p>
      </div>

      <div className={s.popupButtons}>
        <BackToEditBtn pos="left" onClick={close}>
          Вернуться к редактированию
        </BackToEditBtn>
        <WriteTelegramBtn pos="right" onClick={onSendMessageClick}>
          Написать в Telegram
        </WriteTelegramBtn>
      </div>
    </div>
  )
}

// type SuccessfullyPaidSubscriptionModalProps = {
//   human
// }

export function SuccessfullyPaidSubscriptionModal(props: SharedProps) {
  const { close } = props
  const [planName, setPlanName] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (window.PURCHASE_PLAN) {
      setPlanName(window.PURCHASE_PLAN)
    }

    return () => {
      window.PURCHASE_PLAN = undefined
    }
  }, [])

  const onSendMessageClick = () => {
    reachGoal('click_send_message')
    close()
  }

  return (
    <div>
      <img className={s.imgPopup} src={boxStarImage} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>Спасибо за покупку</h1>

      <div className={cx(s.creditsTextPopup, 'bodyM')}>
        {planName && <>Вы оформили {planName} план</>}
        {!planName && <>Вы успешно оформили подписку</>}
      </div>

      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        Управление подпиской и детали доступны в вашем личном кабинете. Если у вас есть вопросы или
        предложения, вы всегда можете связаться с нами через Telegram
      </div>

      <div className={s.popupButtons}>
        <BackToEditBtn pos="left" onClick={close}>
          Вернуться к редактированию
        </BackToEditBtn>
        <WriteTelegramBtn pos="right" onClick={onSendMessageClick}>
          Написать в Telegram
        </WriteTelegramBtn>
      </div>
    </div>
  )
}
