import type { Feature } from './cards/CardPlan'

export const YEARLY_FEATURES: {
  base: Feature[]
  premium: Feature[]
  business: Feature[]
} = {
  base: [
    { label: 'Хранение до 10 карточек' },
    { label: '250 генераций AI фонов в месяц' },
    { label: 'Безлимитное скачивание карточек' },
    { label: 'Редактор для инфографики' },
    { label: 'Стандартная техподдержка' },
    { label: '3 шаблона инфографики', disadvantage: true },
  ],
  premium: [
    {
      label: 'Хранение до 100 карточек',
    },
    {
      label: '2500 генераций AI-фонов в меcяц',
    },
    {
      label: 'Безлимитное скачивание карточек',
    },
    {
      label: '<b>Все</b> шаблоны инфографики',
    },
    {
      label: 'Адаптация размера карточек',
    },
    {
      label: 'Фотоворонка: слайды в карточках',
    },
    {
      label: '24/7 приоритетная поддержка',
    },
  ],
  business: [
    { label: 'Безлимитные сохранение' },
    { label: 'Безлимитные генерации AI-фонов' },
    { label: 'Личный менеджер поддержки' },
    { label: 'Все, что есть в других тарифах' },
  ],
}

export const MONTHLY_FEATURES: {
  base: Feature[]
  premium: Feature[]
  business: Feature[]
} = {
  base: [
    { label: 'Хранение до 10 карточек' },
    { label: '250 генераций AI фонов в месяц' },
    { label: 'Безлимитное скачивание карточек' },
    { label: 'Редактор для инфографики' },
    { label: 'Стандартная техподдержка' },
    { label: '3 шаблона инфографики', disadvantage: true },
  ],
  premium: [
    {
      label: 'Хранение до 100 карточек',
    },
    {
      label: '2500 генераций AI-фонов в месяц',
    },
    {
      label: 'Безлимитное скачивание карточек',
    },
    {
      label: '<b>Все</b> шаблоны инфографики',
    },
    {
      label: 'Адаптация размера карточек',
    },
    {
      label: 'Фотоворонка: слайды в карточках',
    },
    {
      label: '24/7 приоритетная поддержка',
    },
  ],
  business: [
    { label: 'Безлимитные сохранение' },
    { label: 'Безлимитные генерации AI-фонов' },
    { label: 'Личный менеджер поддержки' },
    { label: 'Все, что есть в других тарифах' },
  ],
}
