import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import axios from 'axios';
import {backendUrl} from '../config/app';

export const CheckVersion = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const versionsRef = React.useRef<Record<string, string> | null>(null);

  const CHECK_EVERY_SECONDS = 5 * 60
 
  const fetchVersions = async () => {
    try {
      const response = await axios.get(`${backendUrl()}api/version`)
      const newVersions = response.data;

      if (!versionsRef.current) {
        versionsRef.current = newVersions;
        return;
      }
      const hasUpdates = Object.keys(newVersions).some(
        key => versionsRef.current?.[key] !== newVersions[key]
      );

      if (hasUpdates) {
        setShowSnackbar(true);
      }

      versionsRef.current = newVersions
    } catch (error) {
      console.error('Ошибка при получении версий:', error);
    }
  };
  useEffect(() => {
    fetchVersions();
    const intervalId = setInterval(fetchVersions, CHECK_EVERY_SECONDS * 1000);
    return () => clearInterval(intervalId);
  }, []);  

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Snackbar
      open={showSnackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message="Приложение обновилось, нажмите кнопку перезагрузка"
      action={
        <Button color="primary" size="small" onClick={handleReload}>
          Перезагрузка
        </Button>
      }
    />
  );
};