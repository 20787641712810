import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react'
// import { EditorStateStorage } from '../services/EditorStateStorage'
import { AiService } from '../types/ai'
export interface Card {
  id?: string
  prompt: string
  title: string
  dataUrl: string
  loading: boolean
  updating?: boolean
  meta?: {
    thumbUrl: string
    maskImageUrl: string
    imageUrl: string
    prompt: string
    srv: AiService
  }
}

interface CardsContextType {
  cards: Card[]
  setBackgrounds: React.Dispatch<React.SetStateAction<Card[]>>
  //saveCards: () => void
  loadCards: () => void
}

const CardsContext = createContext<CardsContextType | undefined>(undefined)

interface CardsProviderProps {
  children: ReactNode
}

export const CardsProvider: React.FC<CardsProviderProps> = ({ children }) => {
  const [cards, setBackgrounds] = useState<Card[]>([])

  const loadCards = () => {
    // todo
    // const savedData = EditorStateStorage.loadCardState<Card[]>()
    // console.log('saved data', savedData); // todo delete
    // if (savedData) {
    //   setBackgrounds(savedData)
    // }
  }

  return (
    <CardsContext.Provider value={{ cards, setBackgrounds, loadCards }}>
      {children}
    </CardsContext.Provider>
  )
}

export const useCards = (): CardsContextType => {
  const context = useContext(CardsContext)
  if (context === undefined) {
    throw new Error('useCards must be used within a CardsProvider')
  }
  return context
}
