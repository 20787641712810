import { ChangeEvent } from 'react';
import { Checkbox } from '@mui/material';

interface MarketingConsentCheckboxProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
}

export const MarketingConsentCheckbox = ({ onChange, checked }: MarketingConsentCheckboxProps) => {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label style={{ display: 'inline-block', lineHeight: '0.2', verticalAlign: 'middle' }}>
      <Checkbox 
        size="small"
        checked={checked}
        onChange={handleCheckboxChange} 
        style={{ padding: 0, margin: '0 4px 0 0', verticalAlign: 'middle' }}
      />
      <span style={{ verticalAlign: 'middle' }}>
        Я согласен на получение новостей и специальных предложений по электронной почте
      </span>
    </label>
  );
};