import {store} from "../editor";

export function centerObjectInRect(object: any, rect: any) {
  const scaleByHeight = object.height / object.width > rect.height / rect.width
  const result = { x: 0, y: 0, width: 0, height: 0 }
  if (scaleByHeight) {
    result.width = object.width * (rect.height / object.height)
    result.height = rect.height
  } else {
    result.height = object.height * (rect.width / object.width)
    result.width = rect.width
  }

  result.x = rect.x + rect.width / 2 - result.width / 2
  result.y = rect.y + rect.height / 2 - result.height / 2
  return result
}

export function getCenterPlacement(
  storeWidth: number,
  storeHeight: number,
  imageWidth: number,
  imageHeight: number
) {
  return { x: storeWidth / 2 - imageWidth / 2, y: storeHeight / 2 - imageHeight / 2 }
}

export function getScaledSize(
  imageWidth: number,
  imageHeight: number,
  limitWidth: number,
  limitHeight: number
) {
  if (imageWidth <= limitWidth && imageHeight <= limitHeight)
    return { height: imageHeight, width: imageWidth }
  const scaleByHeight = imageHeight / imageWidth > limitHeight / limitWidth
  const result = { width: 0, height: 0 }
  if (scaleByHeight) {
    result.width = imageWidth * (limitHeight / imageHeight)
    result.height = limitHeight
  } else {
    result.height = imageHeight * (limitWidth / imageWidth)
    result.width = limitWidth
  }
  return result
}

type PlacementMeta = {jsonTemplate: Record<string, any>, setPositionChangedInFunction: (f: boolean) => void}
export function placeObjectInTemplate (store: any, currentObject: any, templateELement: any, meta: PlacementMeta) {
  const {jsonTemplate, setPositionChangedInFunction} = meta
  const boundingRect = {
    x: templateELement.x,
    y: templateELement.y,
    width: templateELement.width,
    height: templateELement.height,
  }
  const { x, y, width, height } = centerObjectInRect(currentObject, boundingRect)
  templateELement.x = x
  templateELement.y = y
  templateELement.width = width
  templateELement.height = height

  templateELement.src = currentObject.src

  if (
      store.activePage.background.includes('/') &&
      !store.activePage.background.includes('http') &&
      !store.activePage.background.includes('infographics') &&
      !jsonTemplate['pages'][0]['background'] &&
      (store.custom.objectPositionBeforeDisplacement.x != templateELement.x ||
          store.custom.objectPositionBeforeDisplacement.y != templateELement.y ||
          store.custom.objectPositionBeforeDisplacement.width != templateELement.width ||
          store.custom.objectPositionBeforeDisplacement.height != templateELement.height ||
          store.custom.objectPositionBeforeDisplacement.rotation != templateELement.rotation)
  ) {
    setPositionChangedInFunction(true)
  }

  if (!store.activePage.background.includes('/')) {
    setObjectBeforeDisplacement(store, currentObject)
  }
  store.activePage.addElement({
    ...templateELement,
    id: `object_${store.activePage.id}`,
    removable: false,
  })
  store.deleteElements([templateELement.id])
}

export function setObjectBeforeDisplacement(store: any, mainObject: any): void {
  setCustomStoreEntityProps(store, {
    objectPositionBeforeDisplacement: {
      x: mainObject.x,
      y: mainObject.y,
      width: mainObject.width,
      height: mainObject.height,
      rotation: mainObject.rotation,
    }})
}

export function setStoreProps(store: any, {backgroundType,
  backgroundKey,
  backgroundPrompt,
  infographicsTemplate,cardSize}: any) {
  if (cardSize) {
    setCustomStoreEntityProps(store, { cardSize: cardSize })
    store.setSize(store.custom.cardSize === '1_1' ? 1200 : 900, 1200)
    store.activePage.setSize({ width: store.custom.cardSize === '1_1' ? 1200 : 900, height: 1200 })
  }

  if (backgroundType) {
    setCustomStoreEntityProps(store, {
        activeBackgroundType: backgroundType
      })
  }

  if (backgroundKey) {
    setCustomStoreEntityProps(store, {
        activeBackgroundKey: backgroundKey
      })
  }

  if (backgroundPrompt) {
    setCustomStoreEntityProps(store, {
        currentPromptInStore: {
          prompt: backgroundPrompt
        }
      })
  }

  if (infographicsTemplate != null) {
    setCustomStoreEntityProps(store, {
        activeInfographicsTemplate: infographicsTemplate
      })
  }
}

export function setCustomStoreEntityProps(entity: any, custom: Record<string, any>) {
  entity.set({custom: {...(store.custom || {}), ...custom}})
}
