import { useRef, useState, useContext} from 'react'
import { reachGoal } from '../../utils/metrics'
import { useDropzone, FileError } from 'react-dropzone'
import { backendUrl } from '../../config/app'
import { createProjectId } from '../../logger/logEvent'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { Button } from '../../components/Button'
import { ReactComponent as PlusIcon } from '../../components/icons/PlusIcon.svg'
import cx from 'clsx'
import { FormControlLabel, Switch } from '@mui/material'
import s from './FileUploadScreen.module.css'
import { ReactComponent as GradientRounded } from '@material-design-icons/svg/round/gradient.svg'
import { resizeImage } from '../../utils/resizeImage'
import { PaywallVariations, useSubscriptions } from "../../store/useSubscriptions";
import { MODAL_NAME as PAYWALL_MODAL } from "../../ds/modal/PayWallModal";
import { useModalManager } from "../../store/useModalManager";
import { FeatureFlagContext } from "../../contexts/FeatureFlagContext";
import { useProjects } from "../../store/useProjects";
import { useResize } from '../../hooks/useResize'


export type FileUploadScreenProps = {
  onFileUploaded: (url: string) => void
}

export function FileUploadScreen(props: FileUploadScreenProps) {
  const { onFileUploaded } = props
  const uploadRef = useRef<HTMLInputElement>(null)
  const { isLoading, setLoading } = useFullPageLoading()
  const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
  const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
  const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
  const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const { isAB } = useContext(FeatureFlagContext)
  const projectsCount = useProjects(s => s.projects.length)
  const { isScreenSm } = useResize()

  const [removeBackground, setRemoveBackground] = useState(true)

  const checkDropPossible = () => {
    if (isAB('packagesPaywall') && projectsCount >= 100 && !hasBusinessSubscription) {
      setPaywallVariation(PaywallVariations.createMoreBusiness)
      toggleModal(PAYWALL_MODAL, true)
      return { message: 'Необходимо улучшить подписку', code: '403'} as FileError;
    } else if (isAB('packagesPaywall') && projectsCount >= 10 && !hasPremiumSubscription) {
      setPaywallVariation(PaywallVariations.createMorePremium)
      toggleModal(PAYWALL_MODAL, true)
      return { message: 'Необходимо улучшить подписку', code: '403'} as FileError;
    } else if (isAB('packages_paywall') && !hasActiveSubscription && projectsCount > 0) {
      setPaywallVariation(PaywallVariations.createMore)
      toggleModal(PAYWALL_MODAL, true)
      return { message: 'Необходимо улучшить подписку', code: '403'} as FileError;
    } else {
      return null;
    }
  }

  const onFileDrop = async (files: File[]) => {

    setLoading(true)
    const resizedFile = await resizeImage(files[0], 2400)

    reachGoal('upload_image')
    const pid = createProjectId()
    const formData = new FormData()
    formData.append('image', resizedFile)
    formData.append('connectionString', 'connectionString')
    setLoading(true)
    // FIXME setBackgroundRemoved(removeBackground)
    try {
      const uploadImagResponse = await fetch(
        `${backendUrl()}api/image/upload?pid=${pid}&removeBackground=${removeBackground}`,
        {
          method: 'POST',
          body: formData,
        }
      )
      const image = await uploadImagResponse.json()

      onFileUploaded(image.imageUrl)
    } catch (e) {
      console.error('Error upload image and create project', e)
    } finally {
      setLoading(false)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: onFileDrop,
    multiple: false,
    noDragEventsBubbling: true,
    validator: checkDropPossible,
  })

  const handleToggleChange = () => setRemoveBackground(!removeBackground)

  return (
    <div className={s.uploaderContainer}>
      <div className={s.uploader}>
        <div
          className={cx(s.uploader_content, { [s.dragActive]: isDragActive })}
          {...getRootProps()}
        >
          <div className={s.uploader_content_title}>
            <h2 className={cx(s.uploader_title_desktop, 'titleM', 'colorTextBlackPrimary')}>Загрузите фотографию</h2>
            <h2 className={cx(s.uploader_title_mobile, 'bodyM', 'colorTextBlackPrimary')}>Загрузите фотографию</h2>
            <div className={s.SubHeader}>
              <h3 className={cx('paragraphL', 'colorTextBlackSecondary', s.dragAndDropText)}>
                Перетащите сюда фотографию продукта
              </h3>
            </div>
          </div>
          <div className={s.uploader_content_divider}>
            <div className="divider-section">
              <hr className="line" />
              <span className="paragraphM colorTextBlackSecondary orInUploader text-between-lines">
                или
              </span>
              <hr className="line" />
            </div>
          </div>
          <input id="upload" ref={uploadRef} type="file" className="hidden" {...getInputProps()} />
          <Button type="button" size='m' disabled={isLoading} grow={false} className={s.upload_button_desktop}>
            <div className={s.upload_button}>
              <PlusIcon />
              <div>
                <span>Выберите фотографию</span>
              </div>
            </div>
          </Button>
          <Button type="button" size='s' disabled={isLoading} grow={false} className={s.upload_button_mobile}>
            <div className={s.upload_button}>
              <PlusIcon />
              <div>
                <span>Выберите фотографию</span>
              </div>
            </div>
          </Button>
        </div>
      </div>
      <div className={cx('bodyM', s.removeBackgroundToggle)}>
        <GradientRounded className={s.removeBackgroundIcon} />
        <FormControlLabel
          control={<Switch checked={removeBackground} onChange={handleToggleChange} />}
          className={s.toggle}
          label={<span className={cx(s.toggleLabel, 'bodyM')}>Удалить фон</span>}
          labelPlacement="start"
        />
      </div>
    </div>
  )
}
