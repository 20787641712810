import styles from './count.module.css'
import { useModalManager } from '../../store/useModalManager'
import { useCredits } from '../../store/useCredits'
// import { MODAL_NAME as CREDITS_MODAL_NAME } from '../../ds/modal/BuyCreditsModal'
// import { SCREEN_PLANS, useScreenManager } from '../../store/useScreenManager'
import { pluralizeGenerations } from '../../utils/pluralizeGenerations'
import { MODAL_NAME as PLANS_MODAL_NAME } from '../../ds/modal/PlansModal'
import { useNavigate } from 'react-router-dom'
import { R_PLANS } from '../../router-constants'
export let countCredits = 0

const Count = () => {
  const credits = useCredits((s) => s.credits)
  const navigate = useNavigate()

  const toggleModal = useModalManager((s) => s.toggleModal)
  // const setScreen = useScreenManager((s) => s.setActiveScreen)
  const openCreditOutPopup = (): void => {
    navigate(R_PLANS)
  }

  return (
    <div onClick={openCreditOutPopup} className={styles.count}>
      <img
        src="/MonetizationOnRounded.svg"
        style={{ width: '32px', height: '32px' }}
        alt="Генерации"
      />
      <span className={`bodyM ${styles.countValue}`}>
        {credits && (credits == -1 ? 'Безлимит' : `${credits} ${pluralizeGenerations(credits)}`)}
        {credits == 0 && ` ${pluralizeGenerations(credits)}`}
      </span>
    </div>
  )
}

export default Count
