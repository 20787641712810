import s from './modal.module.css'
import { useCredits } from '../../store/useCredits'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { reachGoal } from '../../utils/metrics'
import boxStarImage from './box-star.png'
import { useEffect } from 'react'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import cx from 'clsx'
import { pluralizeGenerations } from '../../utils/pluralizeGenerations'

export const MODAL_NAME = 'buyCredits'

export type BuyCreditsModalProps = {
  onBuyClick: () => void
}

export function BuyCreditsModal(props: SharedProps & BuyCreditsModalProps) {
  const { close, onBuyClick } = props
  const credits = useCredits((s) => s.credits)

  const onSendMessageClick = () => {
    reachGoal('click_get_100_credits_in_credits_popup')
    close()
  }

  const onBuyCreditsClick = () => {
    reachGoal('click_buy_credits')
    onBuyClick()
    close()
  }

  useEffect(() => reachGoal('open_credits_popup'), [])

  return (
    <div>
      <img className={s.imgPopup} src={boxStarImage} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>Вы потратили все генерации</h1>

      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>
          Генерации в текущем плане закончились. Вы можете улучшить <br /> план или приобрести
          дополнительные генерации
        </p>
      </div>

      <div className={s.popupButtons}>
        <div className={s.footerContainer}>
          <Button size="m" onClick={onBuyCreditsClick}>
            <div className={s.buttonInput}>
              <div className={s.buttonText}>Перейти к тарифным планам</div>
            </div>
          </Button>

          {/* <Button
            onClick={onSendMessageClick}
            variation="tertiary"
            size="m"
            component="a"
            target="__blank"
            href={`https://t.me/mpcard_support?text=${encodeURIComponent('Я хочу получить 100 бесплатных кредитов MPCard')}`}
          >
            Получить 100 кредитов бесплатно
          </Button> */}
        </div>
      </div>
    </div>
  )
}
