import { backendUrl } from '../config/app'
import axios from 'axios'
import { AiService } from '../types/ai'
import { generateAiBgThumb, type AiBgResponseDTO } from '../api/generateAiThumb'

export const generateAiBackground = async (
  formData: FormData,
  pid: string,
  aiService: AiService
) => {
  return await axios.post(
    `${backendUrl()}api/image/addAIBackground?preview=true&pid=${pid}&s=${aiService}`,
    formData
  )
}

export const generateAiBackgroundVariatively = async (
  formData: FormData,
  pid: string
): Promise<{ imageUrl: string; srv: AiService; meta: AiBgResponseDTO | null }> => {
  const percentageString = process.env.REACT_APP_PHOTOROOM_PERCENTAGE
  const percentage = percentageString ? parseInt(percentageString, 10) : 0

  const isPhotoRoom = Math.random() < percentage / 100

  const aiService: AiService = isPhotoRoom ? 'photoroom' : 'cobalt'

  if (aiService == 'cobalt') {
    const res = await generateAiBgThumb(formData, pid)
    return { imageUrl: res.thumbUrl, meta: res, srv: aiService }
  } else {
    const response = await generateAiBackground(formData, pid, aiService)

    if (response.data && response.data.imageUrl) {
      return { imageUrl: response.data.imageUrl, srv: aiService, meta: null }
    }
    throw new Error('Bad response from bg gen service')
  }
}
