import { useEffect } from 'react'
import { ModalDialog } from '../ModalDialog/ModalDialog'
import { PayWallModal, MODAL_NAME as PAYWALL_MODAL_NAME } from '../../ds/modal/PayWallModal'
import { PayWallModalDialogComponent } from '../ModalDialog/PayWallModalDialogComponent'
import { FullScreenSizeContainer } from '../ModalDialog/FullScreenSizeContainer'
import { PlansModal, MODAL_NAME as PLANS_MODAL_NAME } from '../../ds/modal/PlansModal'
import { OverlayModal, MODAL_NAME as OPEN_OVERLAY } from '../../ds/modal/OverlayModal'
import { OverlayComponent } from '../ModalDialog/OverlayComponent'
import {
  UserProfileModal,
  MODAL_NAME as USER_PROFILE_MODAL_NAME,
} from '../../ds/modal/UserProfileModal'

import {
  SuccessfullyPaidModal,
  SuccessfullyPaidSubscriptionModal,
  MODAL_NAME as PAID_MODAL_NAME,
  MODAL_NAME_SUBSCRIPTION as PAID_SUBSCRIPTION_MODAL_NAME,
} from '../../ds/modal/SuccessfullyPaidModal'

import {
  BuyCreditsModal,
  MODAL_NAME as BUY_CREDITS_MODAL_NAME,
} from '../../ds/modal/BuyCreditsModal'
import { useModalManager } from '../../store/useModalManager'
import { useCredits } from '../../store/useCredits'
import {useNavigate} from "react-router-dom";
import {R_PLANS} from "../../router-constants";
import { useLocation } from 'react-router-dom';

export function AppLevelModals() {
  const toggleModal = useModalManager((s) => s.toggleModal)
  const credits = useCredits((s) => s.credits)
  const location = useLocation();
  const navigate = useNavigate()
  const onBuyCreditsHandler = async () => {
      navigate(R_PLANS)
  }

  useEffect(() => {

    if (credits !== null && credits == 0 && location.pathname != R_PLANS) {
      toggleModal(BUY_CREDITS_MODAL_NAME, true)
    }
  }, [credits])

  return (
    <>
      <ModalDialog
        name={PLANS_MODAL_NAME}
        ContainerComponent={FullScreenSizeContainer}
        render={(p) => <PlansModal {...p} />}
      />
      <ModalDialog
        name={BUY_CREDITS_MODAL_NAME}
        render={(p) => <BuyCreditsModal {...p} onBuyClick={onBuyCreditsHandler} />}
      />

      <ModalDialog
        name={USER_PROFILE_MODAL_NAME}
        ContainerComponent={FullScreenSizeContainer}
        render={(p) => <UserProfileModal {...p} />}
      />
      <ModalDialog name={PAID_MODAL_NAME} render={(p) => <SuccessfullyPaidModal {...p} />} />
      <ModalDialog
        name={PAID_SUBSCRIPTION_MODAL_NAME}
        render={(p) => <SuccessfullyPaidSubscriptionModal {...p} />}
      />

      <ModalDialog
        name={PAYWALL_MODAL_NAME}
        ContainerComponent={PayWallModalDialogComponent}
        render={(p) => <PayWallModal {...p} onBuyClick={onBuyCreditsHandler} />}
      />

      <ModalDialog
        ContainerComponent={OverlayComponent}
        name={OPEN_OVERLAY}
        render={(p) => <OverlayModal {...p} />}
      />
    </>
  )
}
