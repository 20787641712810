import s from './replaceObject.module.css'
import { useRef, useState, useContext} from 'react'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import {PaywallVariations, useSubscriptions} from "../../store/useSubscriptions";
import { useDropzone, FileError } from 'react-dropzone'
import {MODAL_NAME as PAYWALL_MODAL} from "./PayWallModal";
import { backendUrl } from '../../config/app'
import { createProjectId } from '../../logger/logEvent'
import { reachGoal } from '../../utils/metrics'
import { resizeImage } from '../../utils/resizeImage'
import { FeatureFlagContext } from "../../contexts/FeatureFlagContext";
import { useModalManager } from "../../store/useModalManager";
import { useProjects } from "../../store/useProjects";
import { ReactComponent as PlusIconColor } from '../../components/icons/PlusIconColor.svg'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'

export type ReplaceObjectProps = {
  //onReplaceObject: () => void
  onReplaceObject: (url: string) => void
}

export function ReplaceObject(props: ReplaceObjectProps & SharedProps) {
  const { close, onReplaceObject } = props

  const { setLoading, isLoading } = useFullPageLoading()
  const [removeBackground, setRemoveBackground] = useState(true)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
  const { isAB } = useContext(FeatureFlagContext)
  const projectsCount = useProjects(s => s.projects.length)
  const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
  const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
  const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
  const uploadRef = useRef<HTMLInputElement>(null)

  const handleToggleChange = () => setRemoveBackground(!removeBackground)

  const checkDropPossible = () => {
    if (isAB('packagesPaywall') && projectsCount >= 100 && !hasBusinessSubscription) {
      setPaywallVariation(PaywallVariations.createMoreBusiness)
      toggleModal(PAYWALL_MODAL, true)
      return { message: 'Необходимо улучшить подписку', code: '403'} as FileError;
    } else if (isAB('packagesPaywall') && projectsCount >= 10 && !hasPremiumSubscription) {
      setPaywallVariation(PaywallVariations.createMorePremium)
      toggleModal(PAYWALL_MODAL, true)
      return { message: 'Необходимо улучшить подписку', code: '403'} as FileError;
    } else if (isAB('packages_paywall') && !hasActiveSubscription && projectsCount > 0) {
      setPaywallVariation(PaywallVariations.createMore)
      toggleModal(PAYWALL_MODAL, true)
      return { message: 'Необходимо улучшить подписку', code: '403'} as FileError;
    } else {
      return null;
    }
  }

  const onFileDrop = async (files: File[]) => {

    setLoading(true)
    const resizedFile = await resizeImage(files[0], 2400)

    reachGoal('upload_image')
    const pid = createProjectId()
    const formData = new FormData()
    formData.append('image', resizedFile)
    formData.append('connectionString', 'connectionString')
    setLoading(true)
    // FIXME setBackgroundRemoved(removeBackground)
    try {
      const uploadImagResponse = await fetch(
        `${backendUrl()}api/image/upload?pid=${pid}&removeBackground=${removeBackground}`,
        {
          method: 'POST',
          body: formData,
        }
      )
      const image = await uploadImagResponse.json()

      onReplaceObject(image.imageUrl)
      close()

    } catch (e) {
      console.error('Error upload image and create project', e)
    } finally {
      setLoading(false)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: onFileDrop,
    multiple: false,
    noDragEventsBubbling: true,
    validator: checkDropPossible,
  })

  return (
    <div className={s.uploaderContainer}>
      <h2 className={`titleM colorTextBlackPrimary ${s.uploaderPopupTitle}`}>Загрузите фотографию</h2>
      <div className={s.uploader}>
        <div
          className={cx(s.uploader_content, { [s.dragActive]: isDragActive })}
          {...getRootProps()}
        >
          <div className={s.uploader_content_title}>
            <div className={s.SubHeader}>
              <h3 className="paragraphM colorTextBlackTertiary dragAndDropText">
                Перетащите фотографию в эту область
              </h3>
            </div>
          </div>
          <div className={s.dividerSectionInPopup}>
            <hr className={s.lineUploaderInPopup} />
            <span className="paragraphM colorTextBlackSecondary orInUploader text-between-lines">
              или
            </span>
            <hr className={s.lineUploaderInPopup} />
          </div>
          <input id="upload" ref={uploadRef} type="file" className="hidden" {...getInputProps()} />
          <Button className={s.selectButton} type="button" variation = 'secondary' size="m" disabled={isLoading} grow={false}>
            <div className={s.upload_button}>
              <PlusIconColor/>
              <div>
                <span>Выберите фотографию</span>
              </div>
            </div>
          </Button>

          <div className={s.SubHeaderMobile}>
            <PlusIconColor/>
            <h3 className="bodyS colorAccentVioletDefault dragAndDropText">
              Выберите фотографию
            </h3>
          </div>


        </div>
      </div>
      {/* <div className={s.popupButtons}>
        <Button size="m" variation="tertiary" grow={false} onClick={close}>
          Отмена
        </Button>
      </div> */}
    </div>

  )
}
