import { useState } from 'react'
import s from './InputPromoCodeBlock.module.css'
import { Button } from '../../Button'
import { checkPromoCode } from '../../../api/check-promocode'
import { PromoCode } from '../../../domain/types/PromoCode'
import { usePromocode } from '../../../store/usePromocode'
import cx from 'clsx'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check_circle.svg'

type PromoCodeButtonProps = {
  text: string
  onClick: () => void
}

const PromoCodeButton = (props: PromoCodeButtonProps) => {
  const { text, onClick } = props
  return (
    <div onClick={onClick} className={s.ActionButton}>
      {text}
    </div>
  )
}

const AppliedCodeBlock = () => (
  <div className={s.AppliedBlock}>
    <div className={s.checkIcon}>
      <CheckIcon />
    </div>
    <div className={s.AppliedCodeText}>Промокод успешно применен!</div>
  </div>
)

const lookupPlanIds = (v: null | undefined | string[]): null | string[] => {
  if (!v) {
    return null
  }

  if (v.length > 0) {
    return v
  }

  return null
}

export function InputPromoCodeBlock() {
  const [isShown, setShown] = useState(false)
  const isApplied = usePromocode((s) => s.isApplied)
  const applyPromocode = usePromocode((s) => s.setPromocode)

  const [promocode, setPromocode] = useState('')
  const [error, setError] = useState<null | string>(null)

  const onApplyCodeHandler = async () => {
    const result: PromoCode = await checkPromoCode(promocode)

    if (!result.is_valid) {
      setError('Промокод недействителен или не существует')
      return
    }

    applyPromocode(result.code!, result.percent!, lookupPlanIds(result.plans_ids))
  }

  if (isApplied) {
    return (
      <div className={s.Wrapper}>
        <div className={s.FlexBlock}>
          <AppliedCodeBlock />
        </div>
      </div>
    )
  }

  const cancelClickHandler = () => {
    setError(null)
    setShown(false)
  }

  return (
    <div className={s.Wrapper}>
      {!isShown && (
        <div>
          <PromoCodeButton onClick={() => setShown(true)} text="У меня есть промокод" />
        </div>
      )}

      {isShown && (
        <div>
          <div>
            <div>
              <div className={s.InputBlock}>
                <div>
                  <input
                    type="text"
                    className={cx(s.input, 'bodyM')}
                    value={promocode}
                    onChange={(e) => setPromocode(e.target.value)}
                    placeholder="Введите промокод"
                  />

                  {error && (
                    <div>
                      <p className={`paragraphS colorAccentPinkDark ${s.errorText}`}>{error}</p>
                    </div>
                  )}
                </div>
                <Button size="m" variation="tertiary" grow={false} onClick={onApplyCodeHandler}>
                  Применить
                </Button>
              </div>
            </div>

            <div className={s.ButtonBlock}>
              <PromoCodeButton onClick={cancelClickHandler} text="Отменить" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
