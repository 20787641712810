import s from './paywall.module.css'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { ReactComponent as Underline } from './underline.svg'
import { ReactComponent as Star } from '@material-design-icons/svg/round/star.svg'
import { ReactComponent as SizeIcon } from '@material-design-icons/svg/round/sell.svg'
import { ReactComponent as GenerationsIcon } from '@material-design-icons/svg/round/auto_awesome.svg'
import { ReactComponent as Brush } from '@material-design-icons/svg/round/brush.svg'
import { ReactComponent as Collections } from '@material-design-icons/svg/round/collections.svg'
import { ReactComponent as UploadFile } from '@material-design-icons/svg/round/upload_file.svg'
import { ReactComponent as Interests } from '@material-design-icons/svg/round/interests.svg'
import { ReactComponent as TextFields } from '@material-design-icons/svg/round/text_fields.svg'
import { ReactComponent as Download } from '@material-design-icons/svg/round/download.svg'
import { ReactComponent as AddToPhotos } from '@material-design-icons/svg/round/add_to_photos.svg'
import cx from 'clsx'
import { reachGoal } from '../../utils/metrics'
import { useEffect } from 'react'
import { useSubscriptions, PaywallVariations as Variations } from "../../store/useSubscriptions";

export const MODAL_NAME = 'payWall'

export type PayWallModalProps = {
  onBuyClick: () => void,
}



const ToolChips = [
  { icon: SizeIcon, text: 'Адаптация размера карточек', iconClass: s.feature_pink },
  { icon: GenerationsIcon, text: 'Увеличенное количество генерации', iconClass: s.feature_green },
  { icon: Star, text: 'Шаблоны инфографики', iconClass: s.feature_star }
]

const VariationContent = {
  [Variations.createMore]: {
    header: 'Создавайте больше одной карточки',
    headerIcon: Brush,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'Откройте доступ к списку проектов, чтобы создавать и хранить несколько карточек',
    chips: ToolChips,
  },
  [Variations.createMorePremium]: {
    header: 'Создавайте больше десяти карточек',
    headerIcon: Brush,
    headerIconClass: s.feature_purple,
    isPremium: true,
    secondaryText: 'Откройте доступ к большему количеству проектов',
    chips: ToolChips,
  },
  [Variations.createMoreBusiness]: {
    header: 'Создавайте больше ста карточек',
    headerIcon: Brush,
    headerIconClass: s.feature_purple,
    isPremium: true,
    isBusiness: true,
    secondaryText: 'Откройте доступ к неограниченному количеству проектов',
    chips: ToolChips,
  },
  [Variations.backgrounds]: {
    header: 'Откройте доступ к библиотеке фонов',
    headerIcon: Collections,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'Создавайте карточки с нашей библиотекой минималистичных фонов и поиском по тысячам бесплатных фонов из интернета',
    chips: ToolChips,
  },
  [Variations.addImage]: {
    header: 'Добавляйте дополнительные изображения на вашу карточку',
    headerIcon: UploadFile,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'Откройте доступ к добавлению различных изображений, чтобы украсить вашу карточку или вставить логотип',
    chips: ToolChips,
  },
  [Variations.stickers]: {
    header: 'Откройте доступ к созданию фигур',
    headerIcon: Interests,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'Добавляйте и настраивайте фигуры, плашки, стрелки, ищите иконки: сделайте дизайн вашей карточки ещё более уникальным',
    chips: ToolChips,
  },
  [Variations.addText]: {
    header: 'Откройте доступ к созданию текста',
    headerIcon: TextFields,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'Добавляйте новые текстовые блоки, чтобы подчеркнуть уникальность вашего товара',
    chips: ToolChips,
  },
  [Variations.templates]: {
    header: 'Откройте доступ к шаблонам инфографики',
    headerIcon: Star,
    headerIconClass: s.feature_star,
    isPremium: true,
    secondaryText: 'Откройте доступ к шаблонам и сделайте свою инфографику еще лучше',
    chips: [
      { icon: SizeIcon, text: 'Адаптация размера карточек', iconClass: s.feature_pink },
      { icon: Brush, text: 'Доступ к инструментам', iconClass: s.feature_purple },
      { icon: GenerationsIcon, text: 'Увеличенное количество генерации', iconClass: s.feature_green }
    ],
  },
  [Variations.adjustSize]: {
    header: 'Адаптируйте готовую карточку для различных маркетплейсов',
    headerIcon: SizeIcon,
    headerIconClass: s.feature_pink,
    isPremium: true,
    secondaryText: 'Изменяйте размеры и создавайте карточки в одном стиле для любых маркетплейсов одним кликом',
    chips: [
      { icon: Brush, text: 'Доступ к инструментам', iconClass: s.feature_purple },
      { icon: GenerationsIcon, text: 'Увеличенное количество генерации', iconClass: s.feature_green },
      { icon: Star, text: 'Шаблоны инфографики', iconClass: s.feature_star }
    ],
  },
  [Variations.download]: {
    header: 'Скачивайте без ограничений',
    headerIcon: Download,
    headerIconClass: s.feature_purple,
    isPremium: true,
    secondaryText: 'Откройте доступ к скачиванию готовых карточек, чтобы загружать результаты на маркетплейсы',
    chips: [
      { icon: SizeIcon, text: 'Адаптация размера карточек', iconClass: s.feature_pink },
      { icon: GenerationsIcon, text: 'Увеличенное количество генерации', iconClass: s.feature_green },
      { icon: Star, text: 'Шаблоны инфографики', iconClass: s.feature_star }
    ],
  },
  [Variations.multiAsset]: {
    header: 'Создайте фотоворонку продукта для различных маркетплейсов',
    headerIcon: AddToPhotos,
    headerIconClass: s.feature_pink,
    isPremium: true,
    secondaryText: 'Загружайте фотографии продукта в различных ракурсах, создавайте слайды и генерируйте фоны в стиле главной карточки',
    chips: [
      { icon: Brush, text: 'Доступ к инструментам', iconClass: s.feature_purple },
      { icon: GenerationsIcon, text: 'Увеличенное количество генерации', iconClass: s.feature_green },
      { icon: Star, text: 'Шаблоны инфографики', iconClass: s.feature_star }
    ],
  }
}


export function PayWallModal(props: SharedProps & PayWallModalProps) {
  const { close, onBuyClick } = props
  const variation = useSubscriptions(s => s.paywallVariation)
  const content = VariationContent[variation || 'backgrounds'];

  const onBuySubscriptionClick = () => {
    reachGoal(`click_paywall_${variation}`)
    onBuyClick()
    close()
  }

  useEffect(() => reachGoal(`open_paywall_${variation}`), [])

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        <h1 className={cx(s.mainTextPopup, 'titleM')}>
          Разблокируйте
          <br /> особую магию
        </h1>
        <Underline />
      </div>
      <div className={s.body}>
        <div className={cx(s.starImage, content.headerIconClass)}>
          <content.headerIcon />
        </div>

        <h2 className={s.h2}>
          {content.header}
        </h2>

        <div className={cx('bodyM', s.subscriptionType)}>
          {content.isPremium && content.isBusiness
              ? 'Доступно в тарифе Бизнес'
              : content.isPremium
                  ? 'Доступно в тарифах Селлер и Бизнес'
                  : 'Доступно во всех тарифах'
          }
        </div>

        <div className={cx(s.minorTextPopup, 'paragraphM')}>
          {content.secondaryText}
        </div>

        <div className={s.featureBlocks}>
          {content.chips.map(chip => (
            <div className={cx(s.featureBlock, 'paragraphS')}>
              <div className={cx(s.featureIcon, chip.iconClass)}>
                <chip.icon />
              </div>
              {chip.text}
            </div>
          ))}
        </div>

        <div className={s.popupButtons}>
          <div className={s.footerContainer}>
            <Button size="m" onClick={onBuySubscriptionClick}>
              <div className={s.buttonInput}>
                <div className={s.buttonText}>Оформить подписку</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
