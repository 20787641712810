import noProjectsImage from './no-projects.svg'
import s from './NoProjects.module.css'

export function NoProjects() {
  return (
    <div className={s.NoProjectsContainer}>
      <div className={s.image}>
        <img src={noProjectsImage} />
      </div>

      <div className={s.Content}>
        <div className={s.Header}>У вас еще нет сохраненых проектов</div>

        <div className={s.SubHeader}>Загрузите фотографию, чтобы создать свой первый проект</div>
      </div>
    </div>
  )
}
