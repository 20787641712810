import s from './modal.module.css'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { reachGoal } from '../../utils/metrics'
import unsubscribeImage from './unsubscribe-modal-image.svg'
import { useEffect } from 'react'
import cx from 'clsx'

export const MODAL_NAME = 'removeSubscription'

export type RemoveSubscriptionModalProps = {
  onUnsubscribe: (id: string) => void
  onCancel: () => void
  subscriptionId: string
}

export function RemoveSubscriptionModal(props: SharedProps & RemoveSubscriptionModalProps) {
  const { close, onCancel, onUnsubscribe, subscriptionId } = props

  useEffect(() => reachGoal('open_credits_popup'), [])

  return (
    <div>
      <img className={s.imgPopup} src={unsubscribeImage} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>Нам жаль, что вы уходите</h1>

      <div className={cx(s.minorTextPopupRemoveSubscription, 'paragraphM')}>
        <p>
          Вы уверены, что хотите отменить подписку? Мы уже помогли множеству таких же пользователей
          создать карточки для маркетплейсов и поднять их магазины в рейтингах
        </p>
      </div>

      <div className={s.popupButtons}>
        <div className={s.footerContainerRow}>
          <Button size="m" onClick={() => onUnsubscribe(subscriptionId)} variation="tertiary">
            <div className={s.buttonInput}>
              <div className={s.buttonText}>Отменить подписку</div>
            </div>
          </Button>

          <Button onClick={onCancel} variation="primary" size="m">
            Оставить подписку
          </Button>
        </div>
      </div>
    </div>
  )
}
