import {placeObjectInTemplate, setCustomStoreEntityProps} from "./imageUtils/editorUtils";
import {deleteTemplate} from "../aicard/infotemplate";
import {store} from "./editor";

export const getMainObject = (store: any) => store.getElementById(`object_${store.activePage.id}`)
export const getPageObject = (store: any, page: any) => store.getElementById(`object_${page.id}`)

export const addPageTemplate = async (store: any, page: any, template: any, mainObject: any, options: any) => {
    const { infographicsData, replaceTitleInfographicsTemplate, element, replaceAboutTextInfographicsTemplate, setPositionChangedInFunction} = options
    let currentObject = store.getElementById(`object_${page.id}`)

    await store.waitLoading()
    store.addPage()
    store.activePage.setSize({width: store.custom.cardSize === '3_4' ? 900 : 1200, height: 1200, useMagic: false})

    if (page.background.includes('/') && !page.background.includes('infographics')) {
        store.activePage.set({background: page.background})
    } else {
        store.activePage.set({background: template['pages'][0]['background']})
    }

    for (let templateELement of template['pages'][0]['children']) {
        if (templateELement.id === 'object' && currentObject) {
            placeObjectInTemplate(store, currentObject, templateELement, {jsonTemplate: template, setPositionChangedInFunction})
        } else {
            store.activePage.addElement({
                ...templateELement,
                id: `${templateELement.id}_${store.activePage.id}`
            })
        }
    }

    if (infographicsData !== null && !infographicsData.error && element.key !== 0) {
        replaceTitleInfographicsTemplate(element, infographicsData.heading)
        replaceAboutTextInfographicsTemplate(element, infographicsData.features)
    }

    if (infographicsData !== null && element.key !== 0) {
        for (let i = 0; i < element.numberOfHeaderParts; i++) {
            store.getElementById(`main_text_replace_${i}_${store.activePage.id}`)?.set({verticalAlign: 'middle'})
        }
        store.getElementById(`first_about_text_${store.activePage.id}`)?.set({verticalAlign: 'middle'})
        store.getElementById(`second_about_text_${store.activePage.id}`)?.set({verticalAlign: 'middle'})
        store.getElementById(`third_about_text_${store.activePage.id}`)?.set({verticalAlign: 'middle'})
    }
    setCustomStoreEntityProps(mainObject, { infographicsApplied: template !==  deleteTemplate})
}