import React from 'react'
import styles from './register.module.css'
import { Button } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { R_SIGN_IN_PAGE } from '../router-constants'

const AuthThankYou: React.FC<{}> = () => {
  const navigate = useNavigate()

  const goSignIn = () => navigate(R_SIGN_IN_PAGE)
  return (
    <div className={styles.upload}>
      <div className={styles.content}>
        <div className={`${styles.title} ${styles.thanksModal}`}>
          <h2 className="titleL colorTextBlackPrimary">Спасибо!</h2>
          <div className={styles.other}>
            <span className="paragraphMCenter colorTextBlackSecondary">
              Мы отправили пароль на вашу почту. Теперь вы можете войти в аккаунт
            </span>
          </div>
        </div>
        <Button onClick={goSignIn} type="button" size="m">
          Войти в аккаунт
        </Button>
      </div>
    </div>
  )
}

export default AuthThankYou
