import { getProjectId, IInternalEvent, logEvent } from '../logger/logEvent'

export const YM_COUNTER_ID = 97116844
const VK_PIXEL_ID = 3523629

export const reachGoal = (goal: string, params?: Record<string, any>) => {
  if (typeof window.ym !== 'undefined') {
    window.ym(YM_COUNTER_ID, 'reachGoal', goal, params)
  } else {
    console.warn('Yandex Metrica is not initialized')
  }

  if (typeof window._tmr !== 'undefined') {
    window._tmr.push({ type: 'reachGoal', id: VK_PIXEL_ID, goal: goal })
  } else {
    console.warn('VK Pixel is not initialized')
  }

  if (typeof window.amplitude !== 'undefined') {
    if(params?.order_price){
      const event = new window.amplitude.Revenue()
          .setProductId(goal)
          .setPrice(params.order_price)
          .setQuantity(1);
      window.amplitude.revenue(event);
    } else {
      window.amplitude.track(goal, params)
    }
  } else {
    console.warn('Amplitude is not initialized')
  }

  const pid = getProjectId()
  const event: IInternalEvent = { projectId: pid }
  if (params) {
    event.dataJson = params
  }
  logEvent(`user:${goal}`, event)
}

export const sendUserId = (id: number) => {
  if (typeof window.ym !== 'undefined') {
    window.ym(YM_COUNTER_ID, 'userParams', { UserID: id })
  } else {
    console.warn('Yandex Metrica is not initialized')
  }
  if (typeof window.amplitude !== 'undefined') {
    window.amplitude.setUserId(''+id)
  } else {
    console.warn('Amplitude is not initialized')
  }
}
