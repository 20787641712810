import React from 'react'
import styles from './logo.module.css'

const Logo: React.FC = () => {
  return (
    <div className={styles.logoList}>
      <a href="https://mpcard.ai">
        <img src="/mpcard.svg" alt="Logo" />
      </a>
    </div>
  )
}

export default Logo
