import { PropsWithChildren, ReactNode } from 'react'
import s from './styles.module.css'
import cx from 'clsx'
import {AppLevelModals} from "../../AppLevelModals";

export type CleanLayoutProps = {
  header?: ReactNode
}

/**
 * A layout represent clean page with the Header (navbar) support
 * @param props
 * @returns
 */
export function StartPageLayout(props: PropsWithChildren<CleanLayoutProps>) {
  const { children, header } = props

  return (
    <>
    <div className={s.layout}>
      {header && <div>{header}</div>}
      <div className={cx(s.ContentWrapper)}>{children}</div>
    </div>
        <AppLevelModals />
    </>
  )
}
