import styles from './count.module.css'
import { useModalManager } from '../../store/useModalManager'
import { useCredits } from '../../store/useCredits'
import { MODAL_NAME as PLANS_MODAL_NAME } from '../../ds/modal/PlansModal'
import { pluralizeGenerations } from '../../utils/pluralizeGenerations'
import { useNavigate } from 'react-router-dom'
import { R_PLANS } from '../../router-constants'
export let countCredits = 0

export type MobileCountProps = {
  onCreditsClick?: () => void
}

const Count = (p: MobileCountProps) => {
  const { onCreditsClick } = p
  const credits = useCredits((s) => s.credits)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const navigate = useNavigate()

  const openCreditOutPopup = (): void => {
    navigate(R_PLANS)
    if (onCreditsClick) {
      onCreditsClick()
    }
  }

  return (
    <div onClick={openCreditOutPopup} className={styles.mobileCount}>
      <img src="/MonetizationOnRounded.svg" alt="Генерации" />
      <span className={`bodyM ${styles.countValue}`}>
        {credits && (credits == -1 ? 'Безлимит' : `${credits} ${pluralizeGenerations(credits)}`)}
        {credits == 0 && ` ${pluralizeGenerations(credits)}`}
      </span>
    </div>
  )
}

export default Count
