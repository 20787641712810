import { useEffect, useState } from 'react'
import cx from 'clsx'
import { SubscriptionList } from './SubscriptionList'
import { Subscription, useSubscriptions } from '../../../store/useSubscriptions'
import { Button } from '../../Button'
import { deleteSubscription } from '../../../api/deleteSubscription'
import { fetchActiveSubscriptions } from '../../../api/fetchActiveSubscriptions'
import s from './SubscriptionHOC.module.css'
import { NoSubscription } from './NoSubscription'
import { reachGoal } from '../../../utils/metrics'
import {
  RemoveSubscriptionModal,
  MODAL_NAME as REMOVE_SUBSCRIPTION_MODAL_NAME,
} from '../../../ds/modal/RemoveSubscriptionModal'
import { ModalDialog } from '../../ModalDialog/ModalDialog'
import { useModalManager } from '../../../store/useModalManager'

export type SubscriptionHOCProps = {}

const getActive = (s: Subscription[]): string | null => {
  if (s.length > 0) {
    return s[0].id
  }
  return null
}

export function SubscriptionHOC(props: SubscriptionHOCProps) {
  const {} = props
  const toggleModal = useModalManager((s) => s.toggleModal)
  const [isBusy, setBusy] = useState(false)
  const setActiveSubscriptions = useSubscriptions((s) => s.setActiveSubscriptions)
  const sbs = useSubscriptions((s) => s.activeSubscriptions)
  const subscriptionId = getActive(sbs)
  const removeSubscription = async (id: string | null) => {
    if (id) {
      reachGoal('confirm_unsubscribe')
      setBusy(true)
      await deleteSubscription(id)
      const subscriptions = await fetchActiveSubscriptions()
      setActiveSubscriptions(subscriptions)
      setBusy(false)
    }
  }

  const handleRemoveSubscription = () => {
    reachGoal('click_unsubscribe')
    toggleModal(REMOVE_SUBSCRIPTION_MODAL_NAME, true)
  }

  return (
    <div className={s.Container}>
      {sbs.length > 0 && <span className={cx('bodyS', s.CurrentTariffLabel)}>Текущий тариф</span>}
      <div className={s.ListContainer}>
        {sbs.length == 0 ? <NoSubscription /> : <SubscriptionList subscriptions={sbs} />}
      </div>
      {sbs.length > 0 && (
        <div className={s.Actions}>
          <Button
            className={s.Desktop}
            disabled={isBusy}
            variation="tertiary"
            size="s"
            grow={false}
            onClick={handleRemoveSubscription}
          >
            Отменить подписку
          </Button>
          <Button
            className={s.Mobile}
            disabled={isBusy}
            variation="tertiary"
            size="s"
            grow={true}
            onClick={handleRemoveSubscription}
          >
            Отменить подписку
          </Button>
        </div>
      )}

      {sbs.length > 0 && subscriptionId && (
        <ModalDialog
          name={REMOVE_SUBSCRIPTION_MODAL_NAME}
          render={(p) => (
            <RemoveSubscriptionModal
              subscriptionId={subscriptionId}
              onCancel={() => p.close()}
              onUnsubscribe={removeSubscription}
              {...p}
            />
          )}
        />
      )}
    </div>
  )
}
