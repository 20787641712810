import React, { useCallback, useEffect, useRef, useState, useContext } from 'react'
import '@blueprintjs/core/lib/css/blueprint.css'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { SectionTab, SidePanel } from 'polotno/side-panel'
import { Workspace } from 'polotno/canvas/workspace'
import { observer } from 'mobx-react-lite'
import './editor.css'
import { AICard } from '../aicard/aicard'
import { createStore } from 'polotno/model/store'
import debounce from 'lodash/debounce'
import { useFullPageLoading } from '../contexts/FullPageLoadingContext'
import { useCards } from './aiCardsContext'
import { Button } from '../components/Button'
import { TemplateCategory, templateCategories } from '../aicard/aitemplate'
import { infoTemplates, deleteTemplate } from '../aicard/infotemplate'
import { reachGoal } from '../utils/metrics'
import {
  setTranslations,
  unstable_setTextVerticalResizeEnabled,
  unstable_setTextOverflow,
  setAPI,
} from 'polotno/config'
import { polotnoRussianTranslations } from '../utils/polotnoRussianTranslation'
import { backendUrl } from '../config/app'
import { GenBgAdvicePanel } from '../components/GenBgAdvicePanel'

import { takeCredits } from '../api/takeCredits'
import { useCredits } from '../store/useCredits'
import type { Card } from './aiCardsContext'
import { nanoid } from 'nanoid'
import { getProjectId } from '../logger/logEvent'
import { useModalManager } from '../store/useModalManager'
import { LoadingModal } from '../components/LoadingModal/LoadingModal'
import { ReactComponent as DeleteRounded } from './delete-rounded.svg'
import { ReactComponent as UpdateAiBackground } from './update_ai_backgrounds.svg'
import { ReactComponent as HeaderStar } from './header_star.svg'
import { ReactComponent as DollarSymbol } from './dollar_symbol.svg'
import { ReactComponent as AddToPhotos } from '@material-design-icons/svg/round/add_to_photos.svg'
import { ReactComponent as Question } from './question_update_background.svg'
import { ImageResultStore } from '../services/ImageResultStore'
import { ReplaceObject } from '../ds/modal/ReplaceObject'
import { DownloadImage } from '../ds/modal/DownloadImage'
import { ModalDialog } from '../components/ModalDialog/ModalDialog'
import { MODAL_NAME as OPEN_OVERLAY } from '../ds/modal/OverlayModal'
import { MODAL_NAME as CREDITS_MODAL } from '../ds/modal/BuyCreditsModal'
import { MODAL_NAME as PAYWALL_MODAL } from '../ds/modal/PayWallModal'
import {
  useSelectedBackgrounds,
  type ValueType as BgMapType,
} from '../store/useSelectedBackgrounds'
import { ReactComponent as DwlIcon } from '../components/icons/DownloadIcon.svg'
import { useAuthContext } from '../contexts/AuthProvider'
import { ElementsPanel } from 'polotno/side-panel/elements-panel'
import { BackgroundPanel } from 'polotno/side-panel/background-panel'
import { ReactComponent as ElementsIcon } from './elements.svg'
import { ReactComponent as NewCardIcon } from './new_card.svg'
import { ReactComponent as InfographicsIcon } from './infographics.svg'
import { ReactComponent as TextIcon } from './text.svg'
import { ReactComponent as AiBackgroundIcon } from './ai_background.svg'
import { ReactComponent as BackgroundIcon } from './background.svg'
import { ReactComponent as PremiumTemplateIcon } from './premium_template.svg'
import { ReactComponent as UploadFileRounded } from '@material-design-icons/svg/round/upload_file.svg'
import { ReactComponent as ResizeCardIcon } from '@material-design-icons/svg/round/aspect_ratio.svg'
import { ReactComponent as CameraswitchIcon } from '@material-design-icons/svg/round/cameraswitch.svg'
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { ReactComponent as AutoFix } from '@material-design-icons/svg/round/auto_fix_high.svg'

import { FeatureFlagContext } from '../contexts/FeatureFlagContext'
import { upscaleThumb as upscaleThumbAPI } from '../api/upscaleImage'
import { useEditorProductPositionChangeState } from '../store/useEditorProductPositionChangeState'
import { UploadPanel } from './panels/UploadPanel'
import {
  centerObjectInRect,
  getCenterPlacement,
  setCustomStoreEntityProps,
  setObjectBeforeDisplacement, setStoreProps
} from './imageUtils/editorUtils'
const imageResultStore = new ImageResultStore()

import { outpaintBackground as outpaintBackgroundAPI } from '../api/outpaintBackground'
import { RadioButtonForIconsCard } from '../components/RadioButtonForIconsCard/RadioButtonForIconsCard'
import { PaywallVariations, useSubscriptions } from '../store/useSubscriptions'
import { ProjectObject, State, Meta } from '../domain/types/Project'
import { updateProject } from '../api/projects/update-project'
import { useProjects, useProjectsSaving } from '../store/useProjects'
import { updateCover } from '../api/projects/update-cover'
import { useNavigate } from 'react-router-dom'
import {R_PLANS, R_START_PAGE} from '../router-constants'
import { SavingModal, MODAL_NAME as SAVING_MODAL } from '../ds/modal/SavingModal'
import { resizeFromBlob } from '../utils/resizeImage'
import { ReplaceObjectModalDialogComponent } from '../components/ModalDialog/ReplaceObjectModalDialogComponent'
import SidebarTabs from './SidebarTabs'
import { useResize } from '../hooks/useResize'
import {PagesTimeline} from "polotno/pages-timeline";
import MultiAssetPanel from "./multiasset/MultiAssetPanel";
import { getBackgroundGenerator } from './imageUtils/backgroundUtils'
import {cloneDeep, noop} from "lodash";
import {addPageTemplate} from "./utils";


enum BackgroundType {
  Suggested = "suggested",
  Template = "template",
  Prompt = "prompt",
  Default = "default",
  Static = "static"
}

export const store = createStore({
  key: '-GCZl3DuUJYY4M4gVVOq',
  showCredit: false,
})

const saveCurrentProject = async (projectId: string, state: State, meta: Meta) => {
  return await updateProject(projectId, state, meta)
}


function initStore() {
  setCustomStoreEntityProps(store, {
    objectPositionBeforeDisplacement: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      rotation: 0,
    },
    currentPromptInStore: {
      prompt: '',
      negativePrompt: '',
    },
    imageUrl: '',
    cardSize: '3_4',
  })

  unstable_setTextVerticalResizeEnabled(true)
  unstable_setTextOverflow('change-font-size')
  setTranslations(polotnoRussianTranslations)

  store.addPage()
  store.setSize(900, 1200)
}

setAPI('nounProjectList', ({ query, page = 1 }: { query: string; page: number }) => {
  return `${backendUrl()}api/icon_search?query=${query}&page=${page}`
})
setAPI('unsplashList', ({ query, page = 1 }: { query: string; page: number }) => {
  return `${backendUrl()}api/background_search?query=${query}&page=${page}`
})

initStore()

const createProjectStatParams = () => {
  const background = store.activePage.background
  const params = {
    backgroundUrl: background,
    backgroundType: mainObject?.custom?.activeBackgroundType,
    backgroundKey: mainObject?.custom?.activeBackgroundKey,
    backgroundPrompt: store.custom.currentPromptInStore?.prompt || "",
    infographicsTemplate: store.custom.activeInfographicsTemplate || 0
  }
  if (background[0] === "#" || background.includes('http') || background.includes('rgb(')) {
    params.backgroundType = BackgroundType.Static
  }
  if (background.includes('infographics') || background === "white" || background === "transparent") {
    params.backgroundType = BackgroundType.Default
  }
  return params
}


const saveProject = debounce(async (project: ProjectObject, selectedBg: any, cards: any, beforeCb?: () => void, cb?: () => void) => {
  if (beforeCb) {
    beforeCb()
  }
  // prepare cover
  let coverUrl = null
  try {
    const blobData = await store.toBlob({
      pageId: store.activePage.id,
    }) as Blob
    const resized = await resizeFromBlob(blobData, 600)
    const coverUrlRes = await updateCover(resized)
    if (!coverUrlRes.status) {
      console.error('error update cover')
      return
    }
    coverUrl = coverUrlRes.url!
  } catch (e) { }

  const new_meta = { ...project.meta! }

  if (coverUrl) {
    new_meta.coverImage = coverUrl
  }

  try {

    saveCurrentProject(
      project.id,
      {
        sourceImage: project.state.sourceImage,
        polotnoStore: store.toJSON(),
        backgrounds: selectedBg,
        objectPosition: store.custom.objectPositionBeforeDisplacement,
        backgroundType: mainObject?.custom?.activeBackgroundType || BackgroundType.Default,
        backgroundKey: mainObject?.custom?.activeBackgroundKey,
        backgroundPrompt: store.custom.currentPromptInStore?.prompt || "",
        infographicsTemplate: store.custom.activeInfographicsTemplate || 0,
        cards: cards,
        cardSize: store.custom.cardSize,
        infographicData: infographicsData,
      },
      new_meta
    )
  } catch (e) {
    console.log('Unable to save project', e)
  } finally {
    if (cb) {
      cb()
    }

  }
}, 3000)


const NewCardTool = {
  name: 'newCard',
  Tab: (props: any) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const hasActiveSubscription = useSubscriptions((s) => s.hasActiveSubscription)
    const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
    const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
    const projectsCount = useProjects(s => s.projects.length)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const isProjectSaving = useProjectsSaving((s) => s.isSaving)
    const { isAB } = useContext(FeatureFlagContext)
    const navigate = useNavigate()
    const { hasChanged: positionChanged, setValue: setPositionChanged } =
      useEditorProductPositionChangeState((s) => s)

    const createNew = () => {
      if (isProjectSaving) {
        toggleModal(SAVING_MODAL, true)
      } else {
        toggleModal(OPEN_OVERLAY, false)
        setPositionChanged(false) //fix bugs
        navigate(R_START_PAGE)
      }
    }
    const openModalNewCard = function() {
      if (isAB('packages_paywall')) {
        if (projectsCount >= 100 && !hasBusinessSubscription) {
          setPaywallVariation(PaywallVariations.createMoreBusiness)
          toggleModal(PAYWALL_MODAL, true)
        } else if (projectsCount >= 10 && !hasPremiumSubscription) {
          setPaywallVariation(PaywallVariations.createMorePremium)
          toggleModal(PAYWALL_MODAL, true)
        } else if (!hasActiveSubscription) {
          setPaywallVariation(PaywallVariations.createMore)
          toggleModal(PAYWALL_MODAL, true)
        } else {
          createNew()
        }
      } else {
        createNew()
      }
    }
    return (
      <SectionTab name="Новая карточка" {...props} onClick={openModalNewCard}>
        <NewCardIcon />
      </SectionTab>
    )
  },
}

const InfographicsTool = {
  name: 'templates',
  Tab: (props: any) => {
    const toggleModal = useModalManager((s) => s.toggleModal)

    useEffect(() => {
      props.active ? toggleModal(OPEN_OVERLAY, true) : toggleModal(OPEN_OVERLAY, false)
    }, [props.active])

    return(
      <SectionTab name="Инфографика" {...props}>
        <InfographicsIcon />
      </SectionTab>
    )
  },
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store: obsStore }: { store: any }) => {
    const [templateApplied, setTemplateApplied] = useState(false)
    const { isScreenCustom } = useResize()
    const setTemplateAppliedOncePerProject = () => {
      if (store.custom.appliedInProject != getProjectId()) {
        setTemplateApplied(true)
        setCustomStoreEntityProps(store, { appliedInProject: getProjectId() })
      }
    }

    const currentPrompt = useRef({
      prompt: store.custom.currentPromptInStore?.prompt,
      negativePrompt: store.custom.currentPromptInStore?.prompt,
    })

    const { setLoading } = useFullPageLoading()
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { credits, setCredits } = useCredits((s) => ({
      credits: s.credits,
      setCredits: s.setCredits,
    }))
    const [activeTemplateKey, setActiveTemplateKeyInternal] = useState(store.custom.activeInfographicsTemplate ?? 0)
    const setActiveTemplateKey = (key: number) => {
      setCustomStoreEntityProps(store, { activeInfographicsTemplate: key })
      setActiveTemplateKeyInternal(key)
    }

    const setPosition = useEditorProductPositionChangeState((s) => s.setPosition)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)

    const { hasChanged: positionChanged, setValue: setPositionChanged } =
      useEditorProductPositionChangeState((s) => s)

    const onClickAddInfographics = (element: any, premiumRequired: boolean) => {
      if (premiumRequired) {
        setPaywallVariation(PaywallVariations.templates)
        toggleModal(PAYWALL_MODAL, true)
        return
      }
      if(!isScreenCustom){
        store.openSidePanel('')
      }
      setActiveTemplateKey(element.key)
      addTemplate(element, infographicsData, setPositionChanged)
      setTemplateAppliedOncePerProject()
      reachGoal('click_infographics_template')
    }

    const onClickDeleteInfographics = (key: number) => {
      if(!isScreenCustom){
        store.openSidePanel('')
      }
      setActiveTemplateKey(key)
      addTemplate(deleteTemplate, infographicsData, setPositionChanged)
      setTemplateApplied(false)
      reachGoal('click_delete_infographics_template')
    }

    const generateBackground = getBackgroundGenerator({store, imageResultStore, setPosition, mainObject})

    const generateAndApplyBackground = async (
      prompt: string,
      negativePrompt: string,
      name?: string,
      selectAiService: boolean = false,
      noTakeCredits: boolean = false
    ) => {
      if (credits && (credits > 0 || credits == -1)) {
        setLoading(true)
        currentPrompt.current = { prompt: prompt, negativePrompt: negativePrompt }
        const blob = await getMainObjectBlob(store)

        // one step generation
        const res = await generateBackground(blob, prompt, negativePrompt, { selectAiService })
        if (res.imageUrl) {
          // todo unable to apply cobalt image due to it has thumb only
          // improve!
          // it will work only for one step generation

          applyBackground(res.imageUrl)
        } else {
          console.error('Bad image url', res)
        }
        if (!noTakeCredits) {
          const credits: { credits: number; isUnlimited: boolean } = await takeCredits()
          reachGoal("user_spent_credit", createProjectStatParams())
          const { credits: val, isUnlimited } = credits
          if (isUnlimited) {
            setCredits(-1)
          } else {
            setCredits(val)
          }
        }
        setLoading(false)
      } else if (credits !== null && credits <= 0 && credits != -1) {
        toggleModal(CREDITS_MODAL, true)
      }
    }
    const onBackButtonClick = () =>{
      store.openSidePanel('')
      toggleModal(OPEN_OVERLAY, false)
    }

    return (
      <div className="tool-panel info_panel">
        <div className="tool-panel_content">
          <div className="tool-panel_content_text">
            <div className='backButton' onClick={() =>
              onBackButtonClick()
            }>
              <ArrowLeft />
            </div>
            {/* <h2 className="titleS colorTextBlackPrimary">Инфографика</h2> */}
            <h2 className={`${isScreenCustom ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>Инфографика</h2>
          </div>
          {templateApplied && (
            <div className="tool-panel_about_background">
              <GenBgAdvicePanel header="Готово!">
                <Button
                  size="s"
                  onClick={() => {
                    setObjectPositionInStore()
                    store.openSidePanel('ai-background')
                    setTemplateApplied(false)
                  }}
                  style={{ width: '100%', height: '40px' }}
                >
                  Сгенерировать фон
                </Button>
              </GenBgAdvicePanel>
            </div>
          )}
          <div className="tool-panel_content_wrapper content_info_wrapper">
            <span className="odyS colorTextBlackPrimary info_text">
              Выберите понравившийся шаблон инфографики и отредактируйте расположение продукта
            </span>

            <div className="tool-panel_content_category_wrapper info_wrapper">
              <div
                className={
                  activeTemplateKey === deleteTemplate.key
                    ? 'tool-panel_content_template tool-panel_content_template_delete info_elements active'
                    : 'tool-panel_content_template tool-panel_content_template_delete info_elements'
                }
              >
                <div
                  className="tool-panel_content_template_image delete_info"
                  onClick={() => {
                    onClickDeleteInfographics(deleteTemplate.key)
                  }}
                >
                  <div className="panel_content_template_delete bodyS">
                    <DeleteRounded className="infogaphicsTemplate" />
                    <div className="infogaphicsTemplate">Без инфографики</div>
                  </div>
                </div>
              </div>
              {infoTemplates.map((element: any) => {
                const { isAB } = useContext(FeatureFlagContext)
                const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
                const premiumTemplate = isAB('packages_paywall') && !hasPremiumSubscription && !element.isFree

                return (
                  <div
                    className={
                      activeTemplateKey === element.key
                        ? 'tool-panel_content_template info_elements active'
                        : 'tool-panel_content_template info_elements'
                    }
                  >
                    {premiumTemplate && <PremiumTemplateIcon className="premium_icon" />}
                    <img
                      className="tool-panel_content_template_image info_img"
                      src={element.image}
                      alt={element.alt}
                      onClick={() => onClickAddInfographics(element, premiumTemplate)}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          {positionChanged && (
            <div className="tool-panel_content_category_warning">
              <div className="tool-panel_content_category_warning_label">
                <div className="tool-panel_content_category_warning_text">
                  <h3 className="bodyM colorAccentPinkDark">Расположение объекта изменилось</h3>
                </div>
                <div className="tool-panel_content_category_tooltip">
                  <Question />
                  <span className="tool-panel_content_category_tooltip_text">
                    AI фон генерируется под конкретное расположение объекта, чтобы учесть его
                    размеры, создать правильные тени и отражения. Если вы изменили положение
                    объекта, нужно либо создать новый фон, либо вернуть объект на прежнее место.
                  </span>
                </div>
              </div>
              <div className="position-warning-button">
                <Button
                  size="m"
                  variation="primary"
                  onClick={() => {
                    
                    setObjectPositionInStore()
                    setPositionChanged(false)
                    //setObjectPositionInStore()
                    if (
                      store.activePage.background != 'transparent' &&
                      currentPrompt.current.prompt
                    ) {
                      generateAndApplyBackground(
                        currentPrompt.current.prompt,
                        currentPrompt.current.negativePrompt,
                        '',
                        false,
                        false
                      )
                    }
                    if(!isScreenCustom){
                      store.openSidePanel('')
                    }
                    reachGoal('click_regenerate_background')
                  }}
                >
                  <div className="warning-button-label">
                    Подстроить фон
                    <div className="warning-button-label-price">
                      <DollarSymbol style={{ marginTop: '-2px' }} />1
                    </div>
                  </div>
                </Button>
                <Button
                  className="return_object_button"
                  size="m"
                  variation="secondary"
                  onClick={() => {
                    setMainObjectPosition()
                    setPositionChanged(false)
                    if(!isScreenCustom){
                      store.openSidePanel('')
                    }
                    reachGoal('click_return_object')
                  }}
                >
                  Вернуть объект
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }),
}

const ElementsTool = {
  name: 'stickers',
  Tab: (props: any) => {
    const { isAB } = useContext(FeatureFlagContext)
    const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const onTabClick = () => {
      if (isAB('packages_paywall') && !hasActiveSubscription) {
        setPaywallVariation(PaywallVariations.stickers)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        props.onClick()
      }
    }
    useEffect(() => {
      props.active ? toggleModal(OPEN_OVERLAY, true) : toggleModal(OPEN_OVERLAY, false)
    }, [props.active])

    return (
    <SectionTab name="Фигуры" {...props} onClick={onTabClick}>
      <ElementsIcon />
    </SectionTab >)
  },
  //Panel: ElementsPanel,
  Panel: observer(({ store }: { store: any }) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { isScreenCustom } = useResize()
    const onBackButtonClick = () =>{
      store.openSidePanel('')
      toggleModal(OPEN_OVERLAY, false)
    }

    return(
      <div className="tool-panel info_panel">
        <div className="tool-panel_content">
          <div className="tool-panel_content_text">
            <div className='backButton' onClick={() =>
              onBackButtonClick()
            }>
              <ArrowLeft />
            </div>
            {/* <h2 className="titleS colorTextBlackPrimary">Фигуры</h2> */}
            <h2 className={`${isScreenCustom ? 'titleS' : 'bodyL'} Фоны`}>Фигуры</h2>
          </div>
          <div className="tool-panel_content_wrapper content_default_wrapper">
            <ElementsPanel store={store} />
          </div>
        </div>
      </div>
    )
  }),
}

const UploadTool = {
  name: 'upload',
  Tab: (props: any) => {
    const { isAB } = useContext(FeatureFlagContext)
    const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const onTabClick = () => {
      if (isAB('packages_paywall') && !hasActiveSubscription) {
        setPaywallVariation(PaywallVariations.addImage)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        props.onClick()
      }
    }

    useEffect(() => {
      props.active ? toggleModal(OPEN_OVERLAY, true) : toggleModal(OPEN_OVERLAY, false)
    }, [props.active])

    return (
      <SectionTab name="Добавить изображения" {...props} onClick={onTabClick}>
        <UploadFileRounded className="tab-icon" />
      </SectionTab>
    )
  },
  Panel: UploadPanel,
}

const TextsTool = {
  name: 'texts',
  Tab: (props: any) => {
    const { isAB } = useContext(FeatureFlagContext)
    const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)

    const onTabOpen = async () => {
      const textWidth = 240
      const textHeight = 32
      const { x, y } = getCenterPlacement(store.width, store.height, textWidth, textHeight)
      const text = store.activePage.addElement({
        type: 'text',
        placeholder: 'Изменяемый текст',
        fontWeight: 'bold',
        fontSize: textHeight,
        width: textWidth,
        height: textHeight,
        x: x,
        y: y,
      })
      text.moveTop()
    }
    const onTabClick = () => {
      if (isAB('packages_paywall') && !hasActiveSubscription) {
        setPaywallVariation(PaywallVariations.addText)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        onTabOpen()
      }
    }
    return (
      <SectionTab name="Добавить текст" {...props} onClick={onTabClick} >
        <TextIcon />
      </SectionTab>
    )
  },
}

const AiBackgroundTool = {
  name: 'ai-background',
  Tab: (props: any) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    useEffect(() => {
      props.active ? toggleModal(OPEN_OVERLAY, true) : toggleModal(OPEN_OVERLAY, false)
    }, [props.active])

    return (
      <SectionTab name="AI фон" {...props}>
        <AiBackgroundIcon />
      </SectionTab>
    )
  },
  Panel: observer(({ store }: { store: any }) => {
    const { cards, setBackgrounds } = useCards()
    const {isAB} = useContext(FeatureFlagContext)
    const [selectedAIBackgroundTab, setSelectedAIBackgroundTab] = useState(isAB('minimalism_default')?0:1)
    const { isScreenCustom } = useResize()
    const cardsPerTab = 4
    const isFirstTab = selectedAIBackgroundTab === 0
    const tabCards = isFirstTab ? cards.slice(0,cardsPerTab) : cards.slice(cardsPerTab, cardsPerTab * 2)

    const { setLoading } = useFullPageLoading()
    const selectedBackgrounds = useSelectedBackgrounds((s) => s.selectedBg)
    const selectBackground = useSelectedBackgrounds((s) => s.addBackgroundPair)
    const { credits, setCredits } = useCredits((s) => ({
      credits: s.credits,
      setCredits: s.setCredits,
    }))

    const [isAnimatedGenerationButton, setIsAnimatedGenerationButton] = useState(0)

    const toggleModal = useModalManager((s) => s.toggleModal)
    const { hasChanged: positionChanged, setValue: setPositionChanged } =
      useEditorProductPositionChangeState((s) => s)

    const setPosition = useEditorProductPositionChangeState((s) => s.setPosition)
    const productPosition = useEditorProductPositionChangeState((s) => s.position)

    const [activeBackgroundKey, setActiveBackgroundKeyInternal] = useState<number | undefined>()
    const setActiveBackgroundKey = (key: number | undefined) => {
      setCustomStoreEntityProps(mainObject, { activeBackgroundKey: key })
      setActiveBackgroundKeyInternal(key)
    }

    enum PanelState {
      Templates,
      Prompt,
    }

    const isStartAnimationOnGenerationButton = () => {
      setIsAnimatedGenerationButton((prevKey) => prevKey + 1)
    }

    useEffect(() => {
      if (cards[0] && cards[0].meta) {
        const sizeBackground = getImageWidthAndHeight(cards[0].meta.imageUrl)

        sizeBackground.then((value) => {
          if (store.custom.cardSize === '1_1' && value[0] !== value[1]) {
            updateCards()
          }

          if (store.custom.cardSize === '3_4' && value[0] === value[1]) {
            updateCards()
          }
        })
      }
    }, [])

    useEffect(() => {
      if (tabCards[0] && tabCards.every(card => !card.meta && ! card.updating)) {
        updateCards()
      }
    }, [selectedAIBackgroundTab])

    useEffect(() => {
      if (productPosition.x && productPosition.y) {
        if (productPosition.x !== mainObject.x || productPosition.y !== mainObject.y) {
          setPositionChanged(true)
        }
      }
    }, [productPosition])

    const currentPrompt = useRef({
      prompt: store.custom.currentPromptInStore?.prompt,
      negativePrompt: store.custom.currentPromptInStore?.prompt,
    })

    const [panelState, setPanelState] = useState(PanelState.Templates)

    const [customPrompt, setCustomPrompt] = useState('')

    const isActiveCard = (thumb: string | undefined) => {
      if (thumb) {
        return !!selectedBackgrounds[thumb]
      }
      return false
    }

    const getPreviouslySelected = (thumb: string): BgMapType | null => {
      if (thumb) {
        return selectedBackgrounds[thumb]
      }
      return null
    }

    const setActiveCard = (thumb: string, bg: string | null | undefined) => {
      if (thumb) {
        selectBackground(thumb, bg ?? '')
      }
    }

    async function updateCards() {
      setPositionChanged(false)
      setObjectPositionInStore()
      if (cards.length === 0) {
        return false
      }


      setBackgrounds((currentCards: any) => {
        //show loaders on cards
        const newCards = [...currentCards]

        const startIndex = selectedAIBackgroundTab === 0 ? 0 : cardsPerTab;
        const endIndex = selectedAIBackgroundTab === 0 ? cardsPerTab : cardsPerTab * 2;

        for (let i = startIndex; i < endIndex; i++) {
          newCards[i].dataUrl = '/image_loading.svg';
          newCards[i].loading = true;
          newCards[i].updating = true
        }

        return newCards
      })

      const blob = await getMainObjectBlob(store)

      const promises = tabCards.map(async (card, index) => {
        card.loading = true
        if (card.prompt) {
          try {
            await store.waitLoading()

            const { imageUrl, meta, srv } = await generateBackground(blob, card.prompt, '', {
              isSuggested: true,
            })

            const actualIndex = isFirstTab ? index : cards.length - cardsPerTab + index;

            setBackgrounds((currentCards) => {
              const newCards = [...currentCards]
              newCards[actualIndex] = {
                ...newCards[actualIndex],
                dataUrl: imageUrl,
                loading: false,
                updating: false,
                id: nanoid(),
                meta: { ...meta, prompt: card.prompt, srv },
              }
              return newCards
            })

            if (actualIndex + 1000 == activeBackgroundKey) {
              setCustomStoreEntityProps(store, { cardInStore: card })
            }
          } catch (error) {
            console.error('Error fetching data URL:', error)
          }
        }
      })

      await Promise.all(promises)
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      //Draw cards on element attach if empty
      if (cards[0] && cards[0].dataUrl == '/image_loading.svg' && !cards[0].loading) {
        updateCards()
      }
    }, [cards.length])
    /* eslint-enable react-hooks/exhaustive-deps */

    const generateBackground = getBackgroundGenerator({store, imageResultStore, setPosition, mainObject})

    const generateAndApplyBackground = async (
      prompt: string,
      negativePrompt: string,
      name?: string,
      selectAiService: boolean = false,
      noTakeCredits: boolean = false
    ) => {
      if (credits && (credits > 0 || credits == -1)) {
        setLoading(true)
        setCustomStoreEntityProps(store, { currentPromptInStore: {
          prompt: currentPrompt.current.prompt,
            negativePrompt: currentPrompt.current.negativePrompt
          }})
        const blob = await getMainObjectBlob(store)

        // one step generation
        const res = await generateBackground(blob, prompt, negativePrompt, { selectAiService })
        if (res.imageUrl) {
          // todo unable to apply cobalt image due to it has thumb only
          // improve!
          // it will work only for one step generation

          setCustomStoreEntityProps(store, { imageUrl: res.imageUrl })

          applyBackground(res.imageUrl)
        } else {
          console.error('Bad image url', res)
        }
        if (!noTakeCredits) {
          const credits: { credits: number; isUnlimited: boolean } = await takeCredits()
          reachGoal("user_spent_credit", createProjectStatParams())
          const { credits: val, isUnlimited } = credits
          if (isUnlimited) {
            setCredits(-1)
          } else {
            setCredits(val)
          }
        }

        setLoading(false)
      } else if (credits !== null || credits == 0) {
        toggleModal(CREDITS_MODAL, true)
      }
    }
    const generateAndApplyBackgroundForCustomPrompt = async (prompt: string, name?: string) => {
      if (!prompt) {
        return
      }
      if (credits && (credits > 0 || credits == -1)) {
        if (isActiveCard(name)) {
          return
        }
        setLoading(true)
        currentPrompt.current = { prompt: prompt, negativePrompt: '' }
        setCustomStoreEntityProps(store, { currentPromptInStore: {
            prompt: currentPrompt.current.prompt,
            negativePrompt: currentPrompt.current.negativePrompt
          }})
        const blob = await getMainObjectBlob(store)

        const res = await generateBackground(blob, prompt, '', { translate: true })
        if (res.imageUrl) {
          setCustomStoreEntityProps(store, { imageUrl: res.imageUrl })
          applyBackground(res.imageUrl)
        } else {
          console.error('Bad image url', res)
        }
        const credits: { credits: number; isUnlimited: boolean } = await takeCredits()
        reachGoal("user_spent_credit", createProjectStatParams())
        const { credits: val, isUnlimited } = credits
        if (isUnlimited) {
          setCredits(-1)
        } else {
          setCredits(val)
        }
        if (name) {
          setActiveCard(name, '')
        }

        setLoading(false)
      } else if (credits !== null && credits <= 0 && credits != -1) {
        toggleModal(CREDITS_MODAL, true)
      }
    }

    const applyAiBackground = async (c: Card) => {
      if (c.loading) {
        return
      }
      let imageUrl = ''
      const { meta, dataUrl } = c
      const isCobalt = meta && meta.srv == 'cobalt'

      if (isCobalt) {
        imageUrl = meta.thumbUrl
      } else {
        imageUrl = dataUrl
      }

      if ((credits && credits > 0) || (credits && credits == -1)) {
        if (isActiveCard(imageUrl) || !c.dataUrl) {
          if (isCobalt) {
            const mp = getPreviouslySelected(imageUrl)
            if (mp && mp.bg) {
              applyBackground(mp.bg)
            } else {
              setLoading(true)
              await upscaleAndApplyBackground(c)
              setLoading(false)
            }
          } else {
            applyBackground(imageUrl)
          }
        } else {
          if (isCobalt) {
            setLoading(true)
            await upscaleAndApplyBackground(c, (bg: string) => {
              setActiveCard(imageUrl, bg)
            })

            setLoading(false)
          } else {
            applyBackground(imageUrl)
            setActiveCard(imageUrl, '')
          }
          const credits: { credits: number; isUnlimited: boolean } = await takeCredits()
          reachGoal("user_spent_credit", createProjectStatParams())
          const { credits: val, isUnlimited } = credits
          if (isUnlimited) {
            setCredits(-1)
          } else {
            setCredits(val)
          }
        }
        imageResultStore.setCurrentByImageUrl(imageUrl)

        //* now it is possible to use card.meta.srv directly
        reachGoal(`click_suggested_background`)

        currentPrompt.current = { prompt: c.prompt, negativePrompt: '' }
        setCustomStoreEntityProps(store, { currentPromptInStore: {
            prompt: currentPrompt.current.prompt,
            negativePrompt: currentPrompt.current.negativePrompt
          }})
      } else {
        toggleModal(CREDITS_MODAL, true)
      }
    }
    const onBackButtonClick = () =>{
      store.openSidePanel('')
      toggleModal(OPEN_OVERLAY, false)
    }

    return (
      <div className="tool-panel">
        {panelState === PanelState.Templates && (
          <div className="tool-panel_content">
            <div className="tool-panel_content_text">
              <div className='backButton' onClick={() =>
                onBackButtonClick()
              }>
                <ArrowLeft />
              </div>
              {/* <h2 className="titleS colorTextBlackPrimary">AI фон</h2> */}
              <h2 className={`${isScreenCustom ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>AI фон</h2>
              <Button className='createBackgroundDesktop'
                size="s"
                variation="tertiary"
                grow={false}
                onClick={() => {
                  setPanelState(PanelState.Prompt)
                  reachGoal('click_custom_background')
                }}
              >
                Создать свой фон
              </Button>
              <div className='createBackgroundMobile'
                onClick={() => {
                  setPanelState(PanelState.Prompt)
                  reachGoal('click_custom_background')
                }}
              >
                <AutoFix />
              </div>
            </div>
            <div className="tool-panel_content_wrapper content_AI_wrapper">
              <div className="tool-panel_content_suggested">
                <div className="tool-panel_header_line">
                  <div className="tool-panel_header_and_star">
                    <h3 className="bodyM colorAccentVioletDark">Идеальные фоны</h3>
                    <HeaderStar style={{ position: 'absolute', right: '6px', top: '-5px' }} />
                  </div>
                  <div
                    className={`tool-panel_update_content_suggested_button ${tabCards.length != 4 || tabCards.some(card => card.loading) ? 'disable' : ''}`}
                    onClick={() => {
                      reachGoal('click_update_AI_template_background')
                      if (
                        tabCards.length != 4 ||
                        (tabCards.every(card => !card.loading))
                      ) {
                        updateCards()
                      }
                    }}
                  >
                    <UpdateAiBackground />
                  </div>
                </div>

                <SidebarTabs
                  tabs={['Минимализм', 'Реализм']}
                  onTabClick={(value) => {
                    setSelectedAIBackgroundTab(value)
                  }}
                  value={selectedAIBackgroundTab}
                />

                <div className="tool-panel_content_category_suggested_templates">
                  {tabCards.map((card, index) => {
                    const actualIndex = isFirstTab ? index : cards.length - cardsPerTab + index;
                    return (
                      <AICard
                        cardSize={store.custom.cardSize}
                        previewKey={actualIndex + 1000}
                        key={`ai-card-${actualIndex}`}
                        activeBackgroundKey={activeBackgroundKey}
                        setActiveBackgroundKey={setActiveBackgroundKey}
                        cardItem={card}
                        onClick={() => {
                          setCustomStoreEntityProps(store, { cardInStore: card })
                          isStartAnimationOnGenerationButton()
                          reachGoal('click_AI_template_background')
                        }}
                      />
                    )
                  })}
                  {cards.length === 0 && [... new Array(4)].map((_, idx) => (
                    <AICard
                      cardSize={store.custom.cardSize}
                      previewKey={idx + 1000}
                      activeBackgroundKey={activeBackgroundKey}
                      setActiveBackgroundKey={setActiveBackgroundKey}
                      cardItem={{ loading: false, prompt: '', title: '', dataUrl: '/image_loading.svg' }}
                      onClick={() => { }}
                    />
                  ))}
                </div>
              </div>
              <div className="tool-panel_content_templates">
                <h3 className="bodyL colorTextBlackPrimary">Шаблоны AI фонов</h3>
                {templateCategories.map((category: any) => (
                  <TemplateCategory
                    name={category.name}
                    templates={category.templates}
                    generateBackground={generateAndApplyBackground}
                    setActiveBackgroundKey={setActiveBackgroundKey}
                    activeBackgroundKey={activeBackgroundKey}
                    isStartAnimationOnGenerationButton={isStartAnimationOnGenerationButton}
                  />
                ))}
              </div>
            </div>
            {positionChanged && (
              <div className="tool-panel_content_category_warning">
                <div className="tool-panel_content_category_warning_label">
                  <div className="tool-panel_content_category_warning_text">
                    <h3 className="bodyM colorAccentPinkDark">Расположение объекта изменилось</h3>
                  </div>
                  <div className="tool-panel_content_category_tooltip">
                    <Question />
                    <span className="tool-panel_content_category_tooltip_text">
                      AI фон генерируется под конкретное расположение объекта, чтобы учесть его
                      размеры, создать правильные тени и отражения. Если вы изменили положение
                      объекта, нужно либо создать новый фон, либо вернуть объект на прежнее место.
                    </span>
                  </div>
                </div>
                <div className="position-warning-button">
                  <Button
                    size="m"
                    variation="primary"
                    onClick={() => {
                      
                      //setObjectPositionInStore()
                      setPositionChanged(false)
                      setObjectPositionInStore()
                      if (
                        store.activePage.background != 'transparent' &&
                        currentPrompt.current.prompt
                      ) {
                        generateAndApplyBackground(
                          currentPrompt.current.prompt,
                          currentPrompt.current.negativePrompt,
                          '',
                          false,
                          false
                        )
                      }
                      if(!isScreenCustom){
                        store.openSidePanel('')
                      }
                      reachGoal('click_regenerate_background')
                    }}
                  >
                    <div className="warning-button-label">
                      Подстроить фон
                      <div className="warning-button-label-price">
                        <DollarSymbol style={{ marginTop: '-2px' }} />
                        <span>1</span>
                      </div>
                    </div>
                  </Button>
                  <Button
                    className="return_object_button"
                    size="m"
                    variation="secondary"
                    onClick={() => {
                      setMainObjectPosition()
                      setPositionChanged(false)
                      if(!isScreenCustom){
                        store.openSidePanel('')
                      }
                      reachGoal('click_return_object')
                    }}
                  >
                    Вернуть объект
                  </Button>
                </div>
              </div>
            )}
            {!positionChanged && (
              <div className="tool-panel_content_category_warning">
                <div
                  key={isAnimatedGenerationButton}
                  className={`position-warning-button ${activeBackgroundKey ? '' : 'disable'} ${isAnimatedGenerationButton != 0 ? 'animate_generate_button' : ''}`}
                >
                  <Button
                    style={{ position: 'relative', display: 'inline-block' }}
                    size="m"
                    onClick={() => {
                      if (activeBackgroundKey) {
                        if (activeBackgroundKey < 1000) {
                          setCustomStoreEntityProps(mainObject, { activeBackgroundType: BackgroundType.Template })
                          generateAndApplyBackground(
                            store.custom.currentPromptInStore.prompt,
                            store.custom.currentPromptInStore.negativePrompt,
                            '',
                            false,
                            false
                          )
                        } else {
                          setCustomStoreEntityProps(mainObject, { activeBackgroundType: BackgroundType.Suggested })
                          setCustomStoreEntityProps(store, { cardInStore: cards[activeBackgroundKey - 1000] })
                          applyAiBackground(store.custom.cardInStore)
                          if (
                            store.custom.objectPositionBeforeDisplacement.x !== mainObject.x ||
                            store.custom.objectPositionBeforeDisplacement.y !== mainObject.y ||
                            store.custom.objectPositionBeforeDisplacement.height !== mainObject.height ||
                            store.custom.objectPositionBeforeDisplacement.width !== mainObject.width ||
                            store.custom.objectPositionBeforeDisplacement.rotation !== mainObject.rotation
                          ) {
                            setPositionChanged(true)
                          }
                        }
                      }
                      if(!isScreenCustom){
                        store.openSidePanel('')
                      }
                      reachGoal('click_generate_background')
                    }}
                  >
                    <div className="warning-button-label">
                      {!activeBackgroundKey
                        ? 'Сгенерировать'
                        : store.activePage.background.includes('/') &&
                          !store.activePage.background.includes('http') &&
                          activeBackgroundKey < 1000
                          ? 'Сгенерировать новый'
                          : activeBackgroundKey < 1000
                            ? 'Сгенерировать'
                            : 'Применить'}
                      {(!activeBackgroundKey ||
                        activeBackgroundKey < 1000 ||
                        !isActiveCard(cards[activeBackgroundKey - 1000]?.dataUrl)) && (
                          <div className="warning-button-label-price">
                            <DollarSymbol style={{ marginTop: '-2px' }} />
                            <span>1</span>
                          </div>
                        )}
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
        {panelState === PanelState.Prompt && (
          <div className="tool-panel_content">
            <div className="tool-panel_content_text">
              <div
                onClick={() => {
                  setPanelState(PanelState.Templates)
                }}
                style={{ float: 'left', cursor: 'pointer' }}
              >
                <img src="/ArrowBackRounded.svg" alt="Назад" />
              </div>
              <h2
                className="titleS colorTextBlackPrimary"
                style={{ width: '100%', textAlign: 'center' }}
              >
                Создать свой фон
              </h2>
            </div>
            <div
              className="tool-panel_content_wrapper content_custom_background_wrapper"
              style={{ gap: '10px' }}
            >
              <label className={'bodyS'} style={{ width: '306px' }}>
                Опишите фон
              </label>
              <textarea
                placeholder={
                  'Предмет на пляже, стоящий на белом камне, вокруг золотой песок, море на заднем плане, профессиональное фото'
                }
                value={customPrompt}
                className={'BodyM'}
                onChange={(e) => setCustomPrompt(e.target.value)}
                style={{
                  width: '306px',
                  height: '120px',
                  padding: '17px',
                  borderRadius: '20px',
                  border: '1px solid var(--Stroke-Default)',
                  resize: 'none', // Disable resizing
                  outline: 'none', // Disable outline when active
                }}
              />

            </div>
            {positionChanged && (
              <div className="tool-panel_content_category_warning">
                <div className="tool-panel_content_category_warning_label">
                  <div className="tool-panel_content_category_warning_text">
                    <h3 className="bodyM colorAccentPinkDark">Расположение объекта изменилось</h3>
                  </div>
                  <div className="tool-panel_content_category_tooltip">
                    <Question />
                    <span className="tool-panel_content_category_tooltip_text">
                      AI фон генерируется под конкретное расположение объекта, чтобы учесть его
                      размеры, создать правильные тени и отражения. Если вы изменили положение
                      объекта, нужно либо создать новый фон, либо вернуть объект на прежнее место.
                    </span>
                  </div>
                </div>
                <div className="position-warning-button">
                  <Button
                    size="m"
                    variation="primary"
                    onClick={() => {
                      
                      setPositionChanged(false)
                      setObjectPositionInStore()
                      if (store.activePage.background != 'transparent' && (customPrompt || store.custom.currentPromptInStore?.prompt)) {
                        setCustomStoreEntityProps(mainObject, { activeBackgroundType: BackgroundType.Prompt })
                        generateAndApplyBackgroundForCustomPrompt(customPrompt ? customPrompt : store.custom.currentPromptInStore?.prompt)
                      }
                      if(!isScreenCustom){
                        store.openSidePanel('')
                      }
                      reachGoal('click_regenerate_background')
                    }}
                  >
                    <div className="warning-button-label">
                      Подстроить фон
                      <div className="warning-button-label-price">
                        <DollarSymbol style={{ marginTop: '-2px' }} />1
                      </div>
                    </div>
                  </Button>
                  <Button
                    className="return_object_button"
                    size="m"
                    variation="secondary"
                    onClick={() => {
                      setMainObjectPosition()
                      setPositionChanged(false)
                      if(!isScreenCustom){
                        store.openSidePanel('')
                      }
                      reachGoal('click_return_object')
                    }}
                  >
                    Вернуть объект
                  </Button>
                </div>
              </div>
            )}
            {!positionChanged && (
              <div className="tool-panel_content_category_warning">
                <div className={`position-warning-button ${customPrompt ? '' : 'disable'}`}>
                  <Button
                    size="m"
                    onClick={() => {
                      setCustomStoreEntityProps(mainObject, { activeBackgroundType: BackgroundType.Prompt })
                      generateAndApplyBackgroundForCustomPrompt(customPrompt)
                      if(!isScreenCustom){
                        store.openSidePanel('')
                      }
                    }}
                  >
                    <div className="warning-button-label">
                      Сгенерировать
                      <div className="warning-button-label-price">
                        <DollarSymbol style={{ marginTop: '-2px' }} />
                        <span>1</span>
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }),
}

const BackgroundsTool = {
  name: 'backgrounds',
  Tab: (props: any) => {
    const { isAB } = useContext(FeatureFlagContext)
    const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const onTabClick = () => {
      if (isAB('packages_paywall') && !hasActiveSubscription) {
        setPaywallVariation(PaywallVariations.backgrounds)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        props.onClick()
      }
    }
    useEffect(() => {
      props.active ? toggleModal(OPEN_OVERLAY, true) : toggleModal(OPEN_OVERLAY, false)
    }, [props.active])
    return (
    <SectionTab name="Фоны" {...props} onClick={onTabClick}>
      <BackgroundIcon />
    </SectionTab >)
  },
  //Panel: BackgroundPanel,
  Panel: observer(({ store }: { store: any }) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { isScreenCustom } = useResize()
    const onBackButtonClick = () =>{
      store.openSidePanel('')
      toggleModal(OPEN_OVERLAY, false)
    }

    return(
      <div className="tool-panel info_panel">
        <div className="tool-panel_content">
          <div className="tool-panel_content_text">
            <div className='backButton' onClick={() =>
              onBackButtonClick()
            }>
              <ArrowLeft />
            </div>
            {/* <h2 className="titleS colorTextBlackPrimary">Фоны</h2> */}
            <h2 className={`${isScreenCustom ? 'titleS' : 'bodyL'} Фоны`}>AI фон</h2>
          </div>
          <div className="tool-panel_content_wrapper content_default_wrapper">
            <BackgroundPanel store={store} />
          </div>
        </div>
      </div>
    )
  }),
}


const ResizeCardTool = {
  name: 'resizeCard',
  Tab: (props: any) => {
    const { isAB } = useContext(FeatureFlagContext)
    const hasPremiumSubscription = useSubscriptions(s => s.hasPremiumSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const onTabClick = () => {
      if (isAB('packages_paywall') && !hasPremiumSubscription) {
        setPaywallVariation(PaywallVariations.adjustSize)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        props.onClick()
      }
    }

    useEffect(() => {
      props.active ? toggleModal(OPEN_OVERLAY, true) : toggleModal(OPEN_OVERLAY, false)
    }, [props.active])

    return (
      <SectionTab name="Размер карточки" {...props} onClick={onTabClick}>
        <ResizeCardIcon />
      </SectionTab>
    )
  },

  Panel: observer(({ store }: { store: any }) => {
    const { setValue: setPositionChanged } = useEditorProductPositionChangeState((s) => s)
    const { setLoading } = useFullPageLoading()
    const productPosition = useEditorProductPositionChangeState((s) => s.position)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { isScreenCustom } = useResize()

    const outpaintAndApplyBackground = async (
      backgroundUrl: string,
      objectMask: string,
      prompt: string
    ) => {
      const pid = getProjectId()
      const result = await outpaintBackgroundAPI(backgroundUrl, objectMask, prompt, pid)
      if (result.url) {
        store.activePage.set({ background: result.url })
      } else {
        console.error('Url is missing in response', result)
      }
    }

    const getBackgroundWidth = async (): Promise<number> => {
      return new Promise((resolve) => {
        var img = new Image()
        img.src = store.activePage.background
        img.onload = function() {
          resolve(img.width)
        }
      })
    }

    const clickOnResizeButton = async (setPositionChanged: any) => {
      setLoading(true)
      const blob = await getMainObjectBlob(store)
      let backgroundUrl = ''
      let objectMask = ''
      let prompt = ''

      if (mainObject?.custom?.activeBackgroundType) {
        backgroundUrl = store.activePage.background
        objectMask = await createMask(blob)
        prompt =
          mainObject?.custom?.activeBackgroundType === BackgroundType.Suggested
            ? store.custom.cardInStore.prompt
            : store.custom.currentPromptInStore.prompt
      }

      if (mainObject?.custom?.activeBackgroundType === BackgroundType.Suggested || mainObject?.custom?.activeBackgroundType === BackgroundType.Template || mainObject?.custom?.activeBackgroundType === BackgroundType.Prompt) {
        const width = await getBackgroundWidth()
        if (width === 900 && store.custom.cardSize === '1_1') {
          await outpaintAndApplyBackground(backgroundUrl, objectMask, prompt)
        }
      }
      updateInfographicsAndCardSize()
      setObjectPositionInStore()
      productPosition.x = mainObject.x
      productPosition.y = mainObject.y
      setPositionChanged(false)
      setLoading(false)
    }
    const onBackButtonClick = () =>{
      store.openSidePanel('')
      toggleModal(OPEN_OVERLAY, false)
    }

    return (
      <div className="tool-panel">
        <div className="tool-panel_content">
          <div className="tool-panel_content_text">
            <div className='backButton' onClick={() =>
              onBackButtonClick()
            }>
              <ArrowLeft />
            </div>
            {/* <h2 className="titleS colorTextBlackPrimary">Размер карточки</h2> */}
            <h2 className={`${isScreenCustom ? 'titleS' : 'bodyL'} Фоны`}>Размер карточки</h2>
            {/*<ProElement />*/}
          </div>
          <div className="tool-panel_content_wrapper content_sizeCard_wrapper">
            <span className="odyS colorTextBlackPrimary sizeCard_minor_text">
              При изменении размера все примененные характерстики сохранятся и подстроятся
              под&nbsp;новые параметры
            </span>

            <RadioButtonForIconsCard
              selection={store.custom.cardSize === '3_4' ? true : false}
              title={'3:4'}
              text={
                'Все товары для Wildberries и Яндекс Маркет. Одежда, обувь и аксессуары для Ozon и Мегамаркет'
              }
              arrayMarketPlace={[
                '/icon/icon_wb.webp',
                '/icon/icon_ozon.webp',
                '/icon/icon_ym.webp',
                '/icon/icon_mm.webp',
              ]}
              onClick={() => {
                if (store.custom.cardSize !== '3_4') {
                  setCustomStoreEntityProps(store, { cardSize: '3_4' })
                  clickOnResizeButton(setPositionChanged)
                  if(!isScreenCustom){
                    store.openSidePanel('')
                  }
                }
              }}
            />
            <RadioButtonForIconsCard
              selection={store.custom.cardSize === '1_1' ? true : false}
              title={'1:1'}
              text={'Все товары (кроме одежды, обуви и аксессуаров) для МегаМаркет и Ozon'}
              arrayMarketPlace={['/icon/icon_ozon.webp', '/icon/icon_mm.webp']}
              onClick={() => {
                if (store.custom.cardSize !== '1_1') {
                  setCustomStoreEntityProps(store, { cardSize: '1_1' })
                  clickOnResizeButton(setPositionChanged)
                  if(!isScreenCustom){
                    store.openSidePanel('')
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    )
  }),
}

let infographicsData: any = null

type CustomSidePanelProps = { store: any; sections: any, defaultSection: string }
const CustomSidePanel = ({ store, sections, defaultSection }: CustomSidePanelProps) => {
  return <SidePanel store={store} sections={sections} defaultSection={defaultSection} />
}

export type EditorProps = {
  backgroundRemoved: boolean
  project: ProjectObject
}
/* eslint-disable react-hooks/exhaustive-deps */
export const Editor = (props: EditorProps) => {
  const { backgroundRemoved, project } = props
  const sections = [
    NewCardTool,
    InfographicsTool,
    AiBackgroundTool,
    MultiAssetTool,
    BackgroundsTool,
    UploadTool,
    ElementsTool,
    TextsTool,
    ResizeCardTool,
  ] as any
  const { setBackgrounds, cards } = useCards()
  const [isDataSaving, setDataSavingStatus] = useState(false)
  const navigate = useNavigate()
  const setProjectSavingStatus = useProjectsSaving((s) => s.setProjectLoading)
  const projectLoadFlag = useRef<boolean>(false)
  const [textLoadingModalActive, setTextLoadingModalActive] = useState(false)
  const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
  const hasPremiumSubscription = useSubscriptions(s => s.hasPremiumSubscription)
  const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const { isAB } = useContext(FeatureFlagContext)
  const selectedBg = useSelectedBackgrounds((s) => s.selectedBg)
  const loadSelectedBg = useSelectedBackgrounds((s) => s.recreateFromExists)
  const { setValue: setPositionChanged } = useEditorProductPositionChangeState((s) => s)
  const resetActiveProject = useProjects((s) => s.resetActiveProject)
  const { user } = useAuthContext()
  let replaceableObject = store.selectedElements[0];

  const debouncedSetTriggerUpdate = useRef(
    debounce(() => {
      if (
        store.activePage.background.includes('/') &&
        !store.activePage.background.includes('http') &&
        !store.activePage.background.includes('infographics')
      ) {
        if (
          store.custom.objectPositionBeforeDisplacement.x !== mainObject.x ||
          store.custom.objectPositionBeforeDisplacement.y !== mainObject.y
        ) {
          console.log('debouncedSetTriggerUpdate')
          setPositionChanged(true)
        }
      }
    }, 500) // Adjust the delay as needed
  ).current

  const replaceObjectButton = observer(({element} : {element: any}) => {
    return (
      <span className="bp5-popover-target">
        <button
          type="button"
          aria-expanded="false"
          aria-haspopup="menu"
          className="bp5-button bp5-minimal"
          onClick={() => {
            replaceableObject = element
            toggleModal('ReplaceObject', true)
        }}>
          <span aria-hidden="true" className="bp5-icon bp5-icon-layers">
            <CameraswitchIcon />
          </span>
          <span className="bp5 buttonTextReplaceObject">Заменить объект</span>
        </button>
      </span>
    );
  });


  const openPopupDownload = async (): Promise<void> => {
    const dataUrl = await getActivePageBlob(store)
    const pid = project.id
    const formData = new FormData()
    formData.append('file', dataUrl, 'image.jpg')

    const currentAiGeneratorUsed = imageResultStore.getCurrent()
    const currentBackgroundType = mainObject?.custom?.activeBackgroundType

    const params = {
      pid: pid,
      generator: currentAiGeneratorUsed,
      ...createProjectStatParams()
    }
    const paramsQuery = new URLSearchParams(params);
    let storeResultUrl = `${backendUrl()}api/image/store-result?${paramsQuery.toString()}`

    fetch(storeResultUrl,
      {
        method: 'POST',
        body: formData,
      }
    )

    reachGoal(`click_download_result ${currentBackgroundType}`,
      params)
    toggleModal('downloadImage', true)

    setProjectSavingStatus(true)
    saveProject(project, selectedBg, cards, () => {
      setDataSavingStatus(true)
    }, () => {
      setDataSavingStatus(false)
      setProjectSavingStatus(false)
    })


    store.saveAsImage({ fileName: 'MPcard-' + new Date().getTime(), pageId: store.activePage.id })
  }

  const onDownloadClick = () => {
    if (isAB('packages_paywall') && !hasActiveSubscription && !user?.isOld) {
      setPaywallVariation(PaywallVariations.download)
      toggleModal(PAYWALL_MODAL, true)
    } else {
      openPopupDownload()
    }
  }

  useEffect(() => {
    reachGoal(`visit_${store.openedSidePanel}_panel`)
  }, [store.openedSidePanel])

  const DownloadButton = ({ store }: { store: any }) => {
    return (
      <div>
        {/* desktop ver of button */}
        <Button size="s" grow={false} onClick={onDownloadClick} className="editor-hidden-mobile">
          <div className="dwlButton">
            <DwlIcon />
            <div>Скачать</div>
          </div>
        </Button>

        {/* mobile ver of button */}
        <Button
          size="s"
          grow={false}
          onClick={onDownloadClick}
          className="editor-hidden-desktop btn-dwl-mobile"
        >
          <div className="dwlButton">
            <DwlIcon />
          </div>
        </Button>
      </div>
    )
  }

  useEffect(() => {
    const beforeUnloadHandler = (e: any) => {
      if (isDataSaving) {
        e.returnValue = "Сохранение в процессе"
        return "Сохранение в процессе"
      }
    }

    window.addEventListener('beforeunload', beforeUnloadHandler)

    return () => window.removeEventListener('beforeunload', beforeUnloadHandler)


  }, [isDataSaving])

  useEffect(() => {
    const unsubscribe = store.on('change', () => {
      setProjectSavingStatus(true)
      saveProject(project, selectedBg, cards, () => {
        setDataSavingStatus(true)
      }, () => {
        setDataSavingStatus(false)
        setProjectSavingStatus(false)
      })
    })

    return () => unsubscribe()
  }, [])

  const onBackgroundsLoaded = (data: any) => {
    if (data.backgrounds && Array.isArray(data.backgrounds)) {
      setBackgrounds(
          data.backgrounds.map((card: { prompt: any; name: any }) => ({
            prompt: card.prompt,
            title: card.name,
            dataUrl: '/image_loading.svg',
            loading: false,
          }))
      )
    }
  }

  // Loading project
  useEffect(() => {
    if (projectLoadFlag.current) {
      return
    }
    store.clear()
    initStore()
      ; (async () => {
          if (!project.state.infographicData) {
            const {sourceImage} = project.state
            setTextLoadingModalActive(true)
            store.activePage.setSize({width: store.width, height: store.height})
            await addObject(store, sourceImage)
            infographicsData = await getInfographics(sourceImage)
            getSuggestedBackgrounds(sourceImage, onBackgroundsLoaded)
            setTextLoadingModalActive(false)
          } else {
            if (!project.state) {
              return
            }
            const {
              backgrounds,
              cards,
              infographicData,
              polotnoStore,
            } = project.state

            setStoreProps(store, project.state)

            if (polotnoStore) {
              store.loadJSON(polotnoStore)

              mainObject = store.getElementById(`object_${store.activePage.id}`)
              if(!mainObject) {
                const oldObject = store.getElementById(`object`) as any;
                if(oldObject) {
                  store.activePage.addElement({
                    type: 'image',
                    name: 'object',
                    src: oldObject.src,
                    x: oldObject.x,
                    y: oldObject.y,
                    resizable: true,
                    keepRatio: true,
                    removable: false,
                    width: oldObject.width,
                    height: oldObject.height,
                    custom: {},
                    id: `object_${store.activePage.id}`
                  })
                  store.deleteElements([oldObject.id])
                  mainObject = store.getElementById(`object_${store.activePage.id}`)
                }
              }
              infographicsData = infographicData

              const page: any = polotnoStore.pages[0]

              if (cards && Array.isArray(cards) && cards.length > 0 && cards[0].dataUrl !== '/image_loading.svg') {
                setBackgrounds(cards)
              } else {
                if (page) {
                  const img: any = page.children.find((o: any) => o.id == `object_${store.activePage.id}`)

                  if (img) {
                    getSuggestedBackgrounds(img.src, onBackgroundsLoaded)
                  }
                }
              }

              if (Object.keys(backgrounds).length > 0) {
                loadSelectedBg(backgrounds)
              }
            }
          }

          projectLoadFlag.current = true
      })()
  }, [project.state?.infographicData])

  const onClickCreateNewCard = async () => {
    setPositionChanged(false) //fix bugs

    await saveProject(project, selectedBg, cards)
    resetActiveProject()
    navigate(R_START_PAGE, { replace: true })
  }

  const onReplaceObject = async (url: string) => {
    const sizeBackground = await getImageWidthAndHeight(url)
    const objectSize = { width: sizeBackground[0], height: sizeBackground[1] }
    if (replaceableObject) {
      const boundingRect = {
        x: replaceableObject.x,
        y: replaceableObject.y,
        width: replaceableObject.width,
        height: replaceableObject.height,
      }

      const { x, y, width, height } = centerObjectInRect(objectSize, boundingRect)
      replaceableObject.set({ x: x, y: y, width: width, height: height })
    }
    replaceableObject.set({ src: url })

    setObjectPositionInStore()
    setPositionChanged(false)
  }

  const onBuyCreditsHandler = () => {
    navigate(R_PLANS)
  }

  const mainObjectRef = useRef({
    x: mainObject?.x ?? 0,
    y: mainObject?.y ?? 0,
    width: mainObject?.width ?? 0,
    height: mainObject?.height ?? 0,
  })

  useEffect(() => {
    const handleStoreChange = () => {
      // Assuming 'mainObject' might be set elsewhere and could be part of store's state
      let { x, y, width, height } = mainObjectRef.current
      if (mainObject) {
        let hasChanged =
          x !== mainObject.x ||
          y !== mainObject.y ||
          width !== mainObject.width ||
          height !== mainObject.height
        if (hasChanged) {
          // Call the debounced function which only triggers after 500ms of no changes
          debouncedSetTriggerUpdate()
        }

        // Update the ref with the new values
        mainObjectRef.current = {
          x: mainObject.x,
          y: mainObject.y,
          width: mainObject.width,
          height: mainObject.height,
        }
      }
    }

    const unsubscribe = store.on('change', handleStoreChange)

    return () => {
      unsubscribe()
      debouncedSetTriggerUpdate.cancel() // Ensure to cancel the debounced calls on unmount
    }
  }, [store, debouncedSetTriggerUpdate])

  const { featureFlags } = useContext(FeatureFlagContext);
  const defaultSection = 'templates'

  useEffect(() => {
    if (mainObject) {
      setObjectBeforeDisplacement(store, mainObject)
    }
  }, [])

  /* eslint-enable react-hooks/exhaustive-deps */
  return (
    <PolotnoContainer className="fullHW">
      <LoadingModal active={textLoadingModalActive} backgroundRemoved={backgroundRemoved} />
      <SidePanelWrap className="sidePanelWrap">
        <CustomSidePanel store={store} sections={sections} defaultSection={defaultSection} />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar
          store={store}
          downloadButtonEnabled
          components={{
            ActionControls: DownloadButton,
          }}
        />
        <Workspace
          backgroundColor="rgb(240 240 245)"
          store={store}
          components={{ Image: replaceObjectButton, PageControls: () => null }}
        />
        <ZoomButtons store={store} />
        {hasPremiumSubscription && <PagesTimeline store={store} />}
      </WorkspaceWrap>

      <ModalDialog
        name={SAVING_MODAL}
        render={(p) => <SavingModal {...p} onProceed={onClickCreateNewCard} />}
      />
      <ModalDialog
        name={'downloadImage'}
        render={(p) => (
          <DownloadImage
            {...p}
            onCreateNewCard={onClickCreateNewCard}
            onBuyClick={onBuyCreditsHandler}
          />
        )}
      />
      <ModalDialog
        name={'ReplaceObject'}
        ContainerComponent={ReplaceObjectModalDialogComponent}
        render={(p) => <ReplaceObject {...p} onReplaceObject={onReplaceObject} />}
      />
    </PolotnoContainer>
  )
}

let mainObject: any

const addObject = async function(store: any, imageUrl: string) {
  return addImage(store, imageUrl)
}

const getMainObjectBlob = async (store: any) => {
  for (let element of store.activePage.children) {
    if (element.id === `object_${store.activePage.id}`) continue
    element.set({ showInExport: false })
  }
  const oldOpacity = mainObject.opacity //Dirty fix to not create backgrounds with transparent object due to strange behaviour

  mainObject.set({ opacity: 100 })
  const dataUrl = await store.toBlob({
    pageId: store.activePage.id,
    ignoreBackground: true,
    mimeType: 'image/webp', // ❗️ SET image/png if comfy is in use on the back-end side OTHERWISE COMFY WILL FAIL WITH AN ERROR
  })

  mainObject.set({ opacity: oldOpacity })
  for (let element of store.activePage.children) {
    if (element.id === `object_${store.activePage.id}`) continue
    element.set({ showInExport: true })
  }
  return dataUrl
}

// needed to save result to our servers. Will be used in analytics purposes
const getActivePageBlob = async (store: any) => {
  const dataUrl = await store.toBlob({
    pageId: store.activePage.id,
    ignoreBackground: false,
    mimeType: 'image/jpeg',
  })
  return dataUrl
}

const getSuggestedBackgrounds = (imageUrl: string, onLoad: (data: any) => void): void => {
  const pid = getProjectId()
  fetch(
    `${backendUrl()}api/backgroundTemplates?imageUrl=${encodeURIComponent(imageUrl)}&pid=${pid}`
  )
    .then((response: Response) => {
      return response.json() // Parse the response body as JSON
    })
    .then((data) => {
      onLoad(data)
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}

const getInfographics = async (imageUrl: string) => {
  const pid = getProjectId()
  let result = await fetch(
    `${backendUrl()}api/infographics?imageUrl=${encodeURIComponent(imageUrl)}&pid=${pid}`
  )
  return result.json()
}

async function addImage(store: any, imageUrl: string, isNewPage = false): Promise<void> {
  // Fetch the image first to get its dimensions
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  const img = new Image()
  img.src = URL.createObjectURL(blob)
  const pageToAddId = store.activePage.id

  return new Promise((resolve, reject) => {
    // Wait for the image to load to get its natural dimensions
    img.onload = async () => {
      let pageToAdd = store.pages.find((p: {id: string}) => p.id === pageToAddId)

      const existsObject = store.getElementById(`object_${pageToAdd.id}`)
      if (!isNewPage && existsObject) {
        resolve()
        return
      } else if (isNewPage) {
        pageToAdd = store.addPage()
        pageToAdd.setSize({ width: store.width, height: store.height })
        await store.waitLoading()
      }

      const { width, height } = pageToAdd
      const position = centerObjectInRect(
          { width: img.naturalWidth, height: img.naturalHeight },
          { x: width * 0.2, y: height * 0.2, width: width * 0.6, height: height * 0.6 }
      )

      mainObject = pageToAdd.addElement({
        type: 'image',
        name: 'object',
        src: imageUrl,
        x: position.x,
        y: position.y,
        resizable: true,
        keepRatio: true,
        removable: false,
        width: position.width,
        height: position.height,
        id: `object_${pageToAdd.id}`,
        custom: { activeBackgroundType: BackgroundType.Default }
      })
      !isNewPage && store.history.clear()
      resolve()
    }

    img.onerror = (error) => {
      console.error('Failed to load image:', error)
      reject()
    }
  })
}

const applyBackground = function(imageUrl: string) {
  store.activePage.set({ background: imageUrl })
}

const upscaleAndApplyBackground = async (card: Card, fn?: (bg: string) => void) => {
  if (card.meta) {
    const pid = getProjectId()
    const result = await upscaleThumbAPI({ ...card.meta }, pid)

    if (result.url) {
      store.activePage.set({ background: result.url })
      if (fn) {
        fn(result.url)
      }
    } else {
      console.error('Url is missing in response', result)
    }
  }
}

async function addTemplate(element: any, infographicsData: any, setPositionChangedInFunction: any) {
  let template = element.jsonRectangleTemplate
  if (store.custom.cardSize === '1_1' && element.jsonSquareTemplate) {
    template = element.jsonSquareTemplate
  }
  const jsonTemplate = JSON.parse(template)
  const pagesToDelete = []
  const oldPages = cloneDeep(store.pages)

  for(const previousPage of oldPages) {
    await addPageTemplate(store, previousPage, jsonTemplate, mainObject, { infographicsData, replaceTitleInfographicsTemplate, element, replaceAboutTextInfographicsTemplate, setPositionChangedInFunction})
    pagesToDelete.push(previousPage.id)
  }
  store.deletePages(pagesToDelete)
  store.selectPage(store.pages[0].id)
  mainObject = store.getElementById(`object_${store.activePage.id}`)
}

function replaceTitleInfographicsTemplate(element: any, heading: string) {
  const splitString = splitText(
    element.numberOfHeaderParts,
    heading,
    element.indexMainHeadersElement
  )

  for (let i = 0; i < splitString.length; i++) {
    store.getElementById(`main_text_replace_${i}_${store.activePage.id}`)?.set({ text: splitString[i] })
  }
}

export const MultiAssetTool = {
  name: 'multi-asset',
  Tab: (props: any) => {
    const { isAB } = useContext(FeatureFlagContext)
    const hasPremiumSubscription = useSubscriptions(s => s.hasPremiumSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const onTabClick = () => {
      if (isAB('packages_paywall') && !hasPremiumSubscription) {
        setPaywallVariation(PaywallVariations.multiAsset)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        props.onClick()
      }
    }
    return (
        <div className="tab-wrapper divider">
          <div className="tab-divider" />
          <SectionTab name="Фотоворонка" {...props} onClick={onTabClick}>
            <AddToPhotos />
          </SectionTab >
        </div>)
  },
  Panel: observer(({ store: obsStore }: { store: any }) => {
    const addNewPage = async (store: any, imageUrl: string) => {
      await addImage(store, imageUrl, true)
      if(store.custom.activeInfographicsTemplate) {
        await store.waitLoading()
        const template = infoTemplates.find(t => t.key === store.custom.activeInfographicsTemplate)
        if(!template) return
        const jsonTemplate = store.custom.cardSize === '1_1' && template.jsonSquareTemplate? template.jsonSquareTemplate : template.jsonRectangleTemplate
        const oldPage = store.pages[store.pages.length - 1];
        await addPageTemplate(store, oldPage, JSON.parse(jsonTemplate), mainObject,
            { infographicsData, replaceTitleInfographicsTemplate, element: template, replaceAboutTextInfographicsTemplate, setPositionChanged: noop})
        store.deletePages([oldPage.id])
      }
    }
    return <MultiAssetPanel store={obsStore} addImage={addNewPage}></MultiAssetPanel>
  }),
}

function replaceAboutTextInfographicsTemplate(element: any, features: any) {
  let splitString = []
  let aboutTextFieldName = ['first_about_text', 'second_about_text', 'third_about_text']
  if (element.numberOfAboutParts) {
    for (let j = 0; j < features.length; j++) {
      splitString = splitText(
        element.numberOfAboutParts,
        features[j],
        element.indexMainOfAboutElement
      )

      for (let i = 0; i < splitString.length; i++) {
          store.getElementById(`${aboutTextFieldName[j]}_${i}_${store.activePage.id}`)?.set({ text: splitString[i] })
      }
    }
  } else {
    for (let i = 0; i < aboutTextFieldName.length; i++) {
        store.getElementById(`${aboutTextFieldName[i]}_${store.activePage.id}`)?.set({ text: infographicsData.features[i] })
    }
  }
}

function splitText(numberOfParts: number, text: string, indexMainHeadersElement: number) {
  let splitString = []
  let arrayString = Array(numberOfParts).fill('')
  if (numberOfParts == 1) {
    splitString = [text]
  } else {
    const lengthHeadingPart = text.length / numberOfParts
    splitString = text.split(' ')

    if (splitString.length == 1) {
      arrayString[indexMainHeadersElement] = splitString[0]
      splitString = arrayString
    }

    if (splitString.length < numberOfParts && splitString.length != 1)
      splitString.push(...Array(numberOfParts - splitString.length).fill(''))

    if (splitString.length > numberOfParts) {
      let arrayStringIndex = 0

      arrayString[arrayStringIndex] = splitString.shift()

      for (let element of splitString) {
        if (arrayStringIndex + 1 < numberOfParts) {
          if ((arrayString[arrayStringIndex] + ' ' + element).length <= lengthHeadingPart) {
            arrayString[arrayStringIndex] += ' ' + element
          } else {
            arrayStringIndex++
            arrayString[arrayStringIndex] = element
          }
        } else {
          arrayString[arrayStringIndex] += ' ' + element
        }
      }
      splitString = arrayString
    }
  }
  return splitString
}

const createMask = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    canvas.height = 1200
    canvas.width = 900
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, 900, 1200)
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const img = new Image()
      img.src = event.target?.result as string
      img.onload = () => {
        ctx?.drawImage(img, 0, 0, 900, 1200)
        if (ctx) {
          const imageData = ctx.getImageData(0, 0, 900, 1200)
          for (let i = 0; i < imageData.data.length; i += 4) {
            if (
              imageData.data[i] != 255 &&
              imageData.data[i + 1] != 255 &&
              imageData.data[i + 2] != 255
            ) {
              imageData.data[i] = 0
              imageData.data[i + 1] = 0
              imageData.data[i + 2] = 0
            }
          }
          ctx.putImageData(imageData, 0, 0)
        }
        resolve(canvas.toDataURL('image/webp'))
      }
    }
  })
}

const updateInfographicsAndCardSize = () => {
  for(const page of store.pages) {
    if (store.custom.cardSize === '1_1') {
      page.setSize({width: 1200, height: 1200, useMagic: false})
      page.children.forEach((element: any) => {
        if (element.x == 0 && element.width == 900) {
          element.set({width: element.width + 300})
        } else {
          element.set({x: element.x + 150})
        }
      })
    }

    if (store.custom.cardSize === '3_4') {
      page.setSize({width: 900, height: 1200, useMagic: false})
      page.children.forEach((element: any) => {
        if (element.x == 0 && element.width == 1200) {
          element.set({width: element.width - 300})
        } else {
          element.set({x: element.x - 150})
        }
      })
    }
  }
}

const setObjectPositionInStore = () => {
  setObjectBeforeDisplacement(store,mainObject)
}

const setMainObjectPosition = () => {
  mainObject.set({ x: store.custom.objectPositionBeforeDisplacement.x })
  mainObject.set({ y: store.custom.objectPositionBeforeDisplacement.y })
  mainObject.set({ width: store.custom.objectPositionBeforeDisplacement.width })
  mainObject.set({ height: store.custom.objectPositionBeforeDisplacement.height })
  mainObject.set({ rotation: store.custom.objectPositionBeforeDisplacement.rotation })
}

const getImageWidthAndHeight = async (url: string): Promise<Array<number>> => {
  return new Promise((resolve) => {
    var img = new Image()
    img.src = url
    img.onload = function() {
      resolve([img.width, img.height])
    }
  })
}
