import { CleanLayout } from '../components/layouts/CleanLayout'
import Header from '../components/Header/Header'
import { useNavigate } from 'react-router-dom'
import { UserProfileContainer } from '../components/UserProfile/UserProfileContainer'

export function UserProfilePage() {
  const navigate = useNavigate()
  const handleGoBack = () => navigate(-1)
  return (
    <CleanLayout header={<Header />}>
      <UserProfileContainer onClickBack={handleGoBack} />
    </CleanLayout>
  )
}
