import s from './modal.module.css'
import image from './popup_new_card.png'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'

export type SavingModalProps = {
  onProceed: () => void
}

export const MODAL_NAME = 'savingModal'

export function SavingModal(props: SavingModalProps & SharedProps) {
  const { close, onProceed } = props

  return (
    <div>
      <img className={s.imgPopup} src={image} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>Сохранение в процессе</h1>

      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>
          Если вы закроете проект сейчас, информация может не сохраниться
        </p>
      </div>

      <div className={s.popupButtons}>
        <Button size="m" variation="tertiary" grow={false} onClick={close}>
          Вернуться к редактированию
        </Button>
        <Button
          size="m"
          grow={false}
          onClick={() => {
            close()
            onProceed()
          }}
        >
          Всё равно закрыть
        </Button>
      </div>
    </div>
  )
}
