import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import cx from 'clsx'
import { FormControlLabel, Switch } from '@mui/material'
import s from './UploadPanel.module.css'
import { ReactComponent as GradientRounded } from '@material-design-icons/svg/round/gradient.svg'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import { ReactComponent as CloudUpload } from '@material-design-icons/svg/round/cloud_upload.svg'
import { Button } from '../../components/Button'
import { ReactComponent as PlusIcon } from '@material-design-icons/svg/round/add.svg'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { backendUrl } from '../../config/app'
import { resizeImage } from '../../utils/resizeImage'
import { getImageSize } from 'polotno/utils/image'
import { getCenterPlacement, getScaledSize } from '../imageUtils/editorUtils'
import { nanoid } from 'nanoid'
import { MODAL_NAME as OPEN_OVERLAY } from '../../ds/modal/OverlayModal'
import { useModalManager } from '../../store/useModalManager'
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { useResize } from '../../hooks/useResize'

const MAX_FILES = 9

interface FileWithPreview extends File {
  preview: string
  id: string
}

export const UploadPanel = observer(({ store: store }: { store: any }) => {
  const { setLoading } = useFullPageLoading()
  const { isScreenCustom } = useResize()
  const [removeBackground, setRemoveBackground] = useState(false)
  const handleToggleChange = () => setRemoveBackground(!removeBackground)
  const toggleModal = useModalManager((s) => s.toggleModal)

  const [files, setFiles] = useState<FileWithPreview[]>([])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    multiple: true,
    maxFiles: 100, //Limit handled in code
    noDragEventsBubbling: true,
    onDrop: (acceptedFiles) => {
      const limitedAcceptedFiles = acceptedFiles.slice(0, MAX_FILES - files.length)
      setFiles([
        ...files,
        ...limitedAcceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: nanoid(),
          })
        ),
      ])
    },
  })

  const handleRemove = useCallback((index: number, file: FileWithPreview) => {
    setFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, i) => i !== index)
      URL.revokeObjectURL(file.preview)
      return newFiles
    })
  }, [])

  const onAddClick = async () => {
    if (!files.length) return
    setLoading(true)
    const formData = new FormData()

    for (const file of files) {
      if (file.type === 'image/svg+xml') {
        formData.append('file', file)
      } else {
        const resizedFile = await resizeImage(file, 2400)
        formData.append('file', resizedFile)
      }
    }

    try {
      const response = await fetch(
        `${backendUrl()}api/image/uploadMultiple?removeBackground=${removeBackground}`,
        {
          method: 'POST',
          body: formData,
        }
      )

      if (!response.ok) {
        setLoading(false)
        return
      }

      const data = await response.json()
      const promises = []
      if (data.images && data.images.length) {
        for (const imageUrl of data.images) {
          promises.push(addImageByUrl(imageUrl))
        }
      }
      await Promise.all(promises)
      setLoading(false)
      if(!isScreenCustom){
        store.openSidePanel('')
      }
    } catch (error) {
      setLoading(false)
      console.error('Error uploading files:', error)
    }
  }

  const addImageByUrl = async (imageUrl: string) => {
    const { width, height } = await getImageSize(imageUrl)
    const { width: scaledWidth, height: scaledHeight } = getScaledSize(
      width,
      height,
      store.width * 0.8,
      store.height * 0.8
    )

    const { x, y } = getCenterPlacement(store.width, store.height, scaledWidth, scaledHeight)
    store.activePage.addElement({
      type: 'image',
      src: imageUrl,
      width: scaledWidth,
      height: scaledHeight,
      x: x,
      y: y,
    })
  }

  const thumbs = files.map((file, index) => (
    <div className={s.upload_preview} key={file.id}>
      <div className={s.upload_delete} onClick={() => handleRemove(index, file)}>
        <CloseIcon />
      </div>
      <div className={s.upload_inner}>
        <img
          src={file.preview}
          className={s.preview_image}
          onLoad={() => {
            //URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ))

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  const onBackButtonClick = () =>{
    store.openSidePanel('')
    toggleModal(OPEN_OVERLAY, false)
  }

  return (
    <div className="tool-panel">
      <div className="tool-panel_content">
        <div className={cx('tool-panel_content_text'/*, s.panel_heading*/)}>
          <div className='backButton' onClick={() =>
            onBackButtonClick()
          }>
            <ArrowLeft />
          </div>
          {/* <h2 className="titleS colorTextBlackPrimary">Добавить изображения</h2> */}
          <h2 className={`${isScreenCustom ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>Добавить изображения</h2>
        </div>
        <div className={s.content_wrapper}>
          {files.length == 0 && (
            <div
              {...getRootProps({
                className: cx('dropzone', s.upload_container, { 'drag-active': isDragActive }),
              })}
            >
              <input {...getInputProps()} />
              <CloudUpload className={s.upload_icon} />
              <div className={cx('paragraphM', 'colorTextBlackTertiary')}>
                Перетащите или{' '}
                <a className={cx(s.upload_link, 'colorAccentVioletDefault')}>
                  выберите изображения
                </a>
              </div>
            </div>
          )}
          {files.length > 0 && (
            <div className={s.thumbs_container}>
              {thumbs}
              {files.length < MAX_FILES && (
                <div
                  {...getRootProps({
                    className: cx('dropzone', s.upload_button, { 'drag-active': isDragActive }),
                  })}
                >
                  <input {...getInputProps()} /> <CloudUpload className={s.upload_button_icon} />
                </div>
              )}
            </div>
          )}
          <div className={cx('bodyM', s.removeBackgroundToggle)}>
            <FormControlLabel
              control={<Switch checked={removeBackground} onChange={handleToggleChange} />}
              className={s.formControlLabel}
              label={
                <div className={s.labelWrapper}>
                  <span className={cx(s.toggleLabel, 'bodyM')}>
                    <GradientRounded className={s.removeBackgroundIcon} /> Удалить фон
                  </span>
                </div>
              }
              labelPlacement="start"
              classes={{ label: s.labelContainer }}
            />
          </div>

          <Button
            type="button"
            variation="tertiary"
            size="m"
            disabled={files.length == 0}
            grow={true}
            onClick={onAddClick}
            style={{ flexShrink: '0' }}
          >
            <div className={s.add_images_button}>
              <PlusIcon />
              <div>
                <span>Добавить изображения</span>
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
})
