import { Button } from '../components/Button'
import s from './SocialBlock.module.css'
import { ReactComponent as YaIcon } from './ya-icon.svg'
import { ReactComponent as VkIcon } from './vk-icon.svg'

namespace SocialBlock {
  export interface Props {
    onYandexClick: () => void
    onVkClick: () => void
  }
}

export const SocialBlock = (props: SocialBlock.Props) => {
  const { onVkClick, onYandexClick } = props
  return (
    <div className={s.wrapper}>
      <div>
        <Button
          type="button"
          onClick={onYandexClick}
          className={s.socialBtn}
          variation="clean"
          size="m"
        >
          <div className={s.buttonInner}>
            <YaIcon />
            <div>Войти через Yandex</div>
          </div>
        </Button>
      </div>

      <div>
        <Button
          type="button"
          onClick={onVkClick}
          className={s.socialBtn}
          size="m"
          variation="clean"
        >
          <div className={s.buttonInner}>
            <VkIcon />
            <div>Войти через ВКонтакте</div>
          </div>
        </Button>
      </div>
    </div>
  )
}
