import axios from 'axios'

import { backendUrl } from '../config/app'
import { reachGoal } from '../utils/metrics'
const LS_FIRST_CREDIT_FLAG = 'first_credit_flag'

export const takeCredits = async () => {
  const response = await axios.post(`${backendUrl()}api/credits`)
  if (!response?.data.status) {
    console.log('Credit management error')
    return 0
  }
  if (!localStorage.getItem(LS_FIRST_CREDIT_FLAG)) {
    reachGoal('user_spent_first_credit')
    localStorage.setItem(LS_FIRST_CREDIT_FLAG, 'true')
  }
  if (response?.data.credits == 0) {
    reachGoal('user_spent_all_credits')
  }

  return response?.data
}
