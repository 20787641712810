import {useState, useEffect, PropsWithChildren, useContext} from 'react'
import { DiscountLabel } from '../Tabs/misc'
import { Tab } from '../Tabs/Tab'
import { TabsGroup } from '../Tabs/TabGroup'
import { CardPlan, formatRussianRubles } from './cards/CardPlan'
import s from './plan-page.module.css'
import { Button } from '../Button'
import { ReactComponent as IconArrowLeft } from './icon-arrow-left.svg'
import { Switch, Case, Default } from '../CondRender/CondRender'
import { YEARLY_FEATURES, MONTHLY_FEATURES } from './plans-features'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { initRegularPayment } from '../../api/initRegularPayment'
import { useSubscriptions, subscriptionTiers, SubscriptionType, SubscriptionTierType } from '../../store/useSubscriptions'
import { initSubscription } from '../../api/initSubscription'
import { reachGoal } from '../../utils/metrics'
import { ReactComponent as QuestionWhiteIcon } from './cards/question-white.svg'
import { ReactComponent as QuestionIcon } from './question.svg'
import { applyDiscountForPrice } from './promo-code/utils'
import { usePromocode } from '../../store/usePromocode'
import { InputPromoCodeBlock } from './promo-code/InputPromoCodeBlock'
import { FeatureFlagContext } from "../../contexts/FeatureFlagContext";

export type PlansContainerProps = {
  onClickBack: () => void
}

const ClientTypeTips = {
  standard: "Для начинающих селлеров (1-10 товаров)",
  premium: "Для средних селлеров (10-100 товаров)",
  business: "Для крупных селлеров (100+ товаров)",
}

type Plan = 'yearly' | 'monthly' | 'shop'

const formatPrice = (price: number): string => {
  return `Всего ${formatRussianRubles(price)}`
}

export function PlansContainer(props: PlansContainerProps) {
  const { onClickBack } = props
  const isPromoCodeApplied = usePromocode((s) => s.isApplied)
  const { setLoading } = useFullPageLoading()

  const resetPromoCode = usePromocode((s) => s.reset)

  const discountInfo = usePromocode((s) => ({
    plandIds: s.planIds,
    discount: s.discount,
    code: s.code,
  }))

  const applyDiscount = (sum: number, planId: string): number => {
    if (!isPromoCodeApplied) {
      return sum
    }

    return applyDiscountForPrice(sum, planId, discountInfo.plandIds!, discountInfo.discount)
  }
  const areSubscriptionExist = useSubscriptions((s) => s.activeSubscriptions.length > 0)
  const currentPlanDetails = useSubscriptions((s) => s.activeSubscriptions[0]?.planDetails) 

  const {isAB} = useContext(FeatureFlagContext)

  const [planTab, setPlanTab] = useState<Plan>(isAB('packages_paywall') ? 'monthly' : 'yearly')

  useEffect(() => {
    reachGoal('open_plans')
  }, [])

  // reset promocode status after unmount
  useEffect(() => {
    return () => {
      resetPromoCode()
    }
  }, [])

  type ButtonAction = 'buy' | 'current' | 'upgrade' | 'contact_support'
  const getButtonAction = (nextTier: SubscriptionTierType, nextTierType: SubscriptionType): ButtonAction => {
    if (!currentPlanDetails) {
      return 'buy'
    }

    const {tier_type: currentTier, type: currentTierType} = currentPlanDetails
    const currentTierIndex = subscriptionTiers.indexOf(currentTier)
    const nextTierIndex = subscriptionTiers.indexOf(nextTier)

    if (nextTierType === currentTierType) {
      if (currentTierIndex > nextTierIndex) {
        return 'contact_support'
      } else if (currentTierIndex < nextTierIndex) {
        return 'upgrade'
      } else {
        return 'current'
      }
    } else if (currentTierType === 'MONTHLY' && nextTierType === 'ANNUAL') { 
      if (nextTierIndex >= currentTierIndex) {
        return 'upgrade'
      } else {
        return 'contact_support'
      }
    } else if (currentTierType === 'ANNUAL' && nextTierType === 'MONTHLY') {
      return 'contact_support'
    }

    return 'contact_support'
  }

  const handleSubscriptionClick = async (planId: string, tier: SubscriptionTierType, type: SubscriptionType) => {
    reachGoal('click_buy_credits')
    const buttonAction = getButtonAction(tier, type)

    if (buttonAction === 'buy' || buttonAction === 'upgrade') {
      setLoading(true);
      try {
        const url = await initSubscription(planId, discountInfo.code ?? undefined) 
        location.href = url;
      } catch (e) {
        console.error(e);
        alert('Не удалось инициировать оплату. Попробуйте позже');
      } finally {
        setLoading(false);
      }
    } else {
      const contactSupportUrl = `https://t.me/mpcard_support?text=${encodeURIComponent('Я хочу поменять план подписки:\n')}`
      window.open(contactSupportUrl, '_blank');
    }
  }

  const handleRegularPayment = async (option: string) => {
    setLoading(true)
    reachGoal('click_buy_credits')
    try {
      const url = await initRegularPayment(option)
      location.href = url
    } catch (e) {
      console.error(e)
      alert('Не удалось инициировать платеж. Попробуйте позже')
    } finally {
      setLoading(false)
    }
  }

  const withAnalytics = (event: string, id: string, tier: SubscriptionTierType, type: SubscriptionType) => () => {
    reachGoal(event)
    handleSubscriptionClick(id, tier, type)
  }

  const withAnalyticsPacket = (event: string, opt: string) => () => {
    reachGoal(event)
    handleRegularPayment(opt)
  }

  const checkDiscountAllowed = (planId?: string): boolean => {
    if (!planId || !isPromoCodeApplied || !discountInfo) {
      return false
    }

    if (discountInfo.plandIds && discountInfo.plandIds.length > 0) {
      return discountInfo.plandIds.includes(planId)
    }

    return true
  }


  const getButtonText = (tier: SubscriptionTierType, type: SubscriptionType) => {
    const labels = {
      'buy': 'Купить',
      'current': 'Текущий план',
      'upgrade': 'Улучшить план',
      'contact_support': 'Сменить план'
    }

    const buttonAction = getButtonAction(tier, type)

    return labels[buttonAction]
  }

  const isButtonDisabled = (tier: SubscriptionTierType, type: SubscriptionType) => { 
    return getButtonAction(tier, type) === 'current'
  }

  return (
    <div className={s.Page}>
      <div>
        <div className={s.TopActions}>
          <Button size="s" variation="secondary" grow={false} onClick={onClickBack} className={s.BackButtonContainer}>
            <div className={s.BackButton}>
              <div>
                <IconArrowLeft />
              </div>
              <div>Вернуться в редактор</div>
            </div>
          </Button>
        </div>
        <div className={s.HeaderContainer}>
          <h1 className={s.Header}>Выберите тарифный план</h1>
          <div className={s.SubHeader}>
            Приобретите комфортный для себя план <br />и откройте <span className={s.hg}>-40%</span>{' '}
            за годовой пакет!
          </div>

          <InputPromoCodeBlock />
        </div>

        <div className={s.TabsContainer}>
          <TabsGroup>
            <Tab onClick={() => setPlanTab('yearly')} isActive={planTab == 'yearly'} size="m">
              Годовой
              <DiscountLabel percents={40} />
            </Tab>
            <Tab onClick={() => setPlanTab('monthly')} isActive={planTab == 'monthly'} size="m">
              Ежемесячный
            </Tab>
            <Tab onClick={() => setPlanTab('shop')} isActive={planTab == 'shop'} size="m">
              Онлайн-магазин
              <div className={s.tooltip}>
                {planTab == 'shop' ? <QuestionWhiteIcon /> : <QuestionIcon />}
                <div className={s.tooltip_text}>Доступно при действующем тарифе</div>
              </div>
            </Tab>
          </TabsGroup>
        </div>

        <div className={s.PlanContainer}>
          <Switch>
            <Case condition={planTab == 'yearly'}>
              <CardPlan
                originalPrice={540}
                discountAmount={discountInfo.discount}
                planId="bcc27de8-e1fa-481d-a347-4ea4c7f84267"
                title="Начинающий"
                clientTypeTip={ClientTypeTips.standard}
                price={Math.floor(applyDiscount(6490, 'bcc27de8-e1fa-481d-a347-4ea4c7f84267') / 12)}
                pricePostfix="месяц"
                priceDescription={formatPrice(
                  applyDiscount(6490, 'bcc27de8-e1fa-481d-a347-4ea4c7f84267')
                )}
                onButtonClick={withAnalytics(
                  'click_plan_basic_annual',
                  'bcc27de8-e1fa-481d-a347-4ea4c7f84267',
                  'standard',
                  'ANNUAL'
                )}
                buttonText={getButtonText('standard', 'ANNUAL')}
                disableButton={isButtonDisabled('standard', 'ANNUAL')}
                featuresList={YEARLY_FEATURES.base}
                checkDiscountAllowed={checkDiscountAllowed}
              />
              <CardPlan
                title="Селлер"
                subTitle="Рекомендуем"
                clientTypeTip={ClientTypeTips.premium}
                discountAmount={discountInfo.discount}
                price={Math.floor(
                  applyDiscount(24990, 'f54da799-05ba-4883-97dc-5ae58f0f7c2a') / 12
                )}
                originalPrice={2082}
                planId="f54da799-05ba-4883-97dc-5ae58f0f7c2a"
                priceDescription={formatPrice(
                  applyDiscount(24990, 'f54da799-05ba-4883-97dc-5ae58f0f7c2a')
                )}
                highlight={true}
                pricePostfix="месяц"
                onButtonClick={withAnalytics(
                  'click_plan_premium_annual',
                  'f54da799-05ba-4883-97dc-5ae58f0f7c2a',
                  'premium', 
                  'ANNUAL'
                )}
                buttonText={getButtonText('premium', 'ANNUAL')}
                disableButton={isButtonDisabled('premium', 'ANNUAL')}
                featuresList={YEARLY_FEATURES.premium}
                checkDiscountAllowed={checkDiscountAllowed}
              />
              <CardPlan
                title="Бизнес"
                discountAmount={discountInfo.discount}
                clientTypeTip={ClientTypeTips.business}
                price={Math.floor(
                  applyDiscount(64490, '5086fe41-54e1-4f41-99e5-b211db771c97') / 12
                )}
                originalPrice={5374}
                planId="5086fe41-54e1-4f41-99e5-b211db771c97"
                priceDescription={formatPrice(
                  applyDiscount(64490, '5086fe41-54e1-4f41-99e5-b211db771c97')
                )}
                pricePostfix="месяц"
                onButtonClick={withAnalytics(
                  'click_plan_business_annual',
                  '5086fe41-54e1-4f41-99e5-b211db771c97',
                  'business', 
                  'ANNUAL'
                )}
                buttonText={getButtonText('business', 'ANNUAL')}
                disableButton={isButtonDisabled('business', 'ANNUAL')}
                featuresList={YEARLY_FEATURES.business}
                checkDiscountAllowed={checkDiscountAllowed}
              />
            </Case>

            <Case condition={planTab == 'monthly'}>
              <ComponentWithAnalytics event="visit_monthly_plans">
                <CardPlan
                  title="Начинающий"
                  clientTypeTip={ClientTypeTips.standard}
                  discountAmount={discountInfo.discount}
                  price={applyDiscount(890, '016f62c0-9990-4a7a-b0de-3d490ebaee99')}
                  originalPrice={890}
                  pricePostfix="месяц"
                  planId="016f62c0-9990-4a7a-b0de-3d490ebaee99"
                  onButtonClick={withAnalytics(
                    'click_plan_basic_monthly',
                    '016f62c0-9990-4a7a-b0de-3d490ebaee99',
                    'standard', 
                    'MONTHLY'
                  )}
                  buttonText={getButtonText('standard', 'MONTHLY')}
                  disableButton={isButtonDisabled('standard', 'MONTHLY')}
                  featuresList={MONTHLY_FEATURES.base}
                  checkDiscountAllowed={checkDiscountAllowed}
                />
                <CardPlan
                  title="Селлер"
                  subTitle="Рекомендуем"
                  clientTypeTip={ClientTypeTips.premium}
                  discountAmount={discountInfo.discount}
                  price={applyDiscount(3490, '0710e2f7-1494-440c-a06e-6c9067f7d757')}
                  originalPrice={3490}
                  planId="0710e2f7-1494-440c-a06e-6c9067f7d757"
                  highlight={true}
                  pricePostfix="месяц"
                  onButtonClick={withAnalytics(
                    'click_plan_premium_monthly',
                    '0710e2f7-1494-440c-a06e-6c9067f7d757',
                    'premium', 
                    'MONTHLY'
                  )}
                  buttonText={getButtonText('premium', 'MONTHLY')}
                  disableButton={isButtonDisabled('premium', 'MONTHLY')}
                  featuresList={MONTHLY_FEATURES.premium}
                  checkDiscountAllowed={checkDiscountAllowed}
                />
                <CardPlan
                  title="Бизнес"
                  discountAmount={discountInfo.discount}
                  clientTypeTip={ClientTypeTips.business}
                  price={applyDiscount(8990, '9907a2ac-da83-47d3-a491-0e6f8b9cfc8f')}
                  originalPrice={8990}
                  planId="9907a2ac-da83-47d3-a491-0e6f8b9cfc8f"
                  pricePostfix="месяц"
                  onButtonClick={withAnalytics(
                    'click_plan_business_monthly',
                    '9907a2ac-da83-47d3-a491-0e6f8b9cfc8f',
                    'business', 
                    'MONTHLY'
                  )}
                  buttonText={getButtonText('business', 'MONTHLY')}
                  disableButton={isButtonDisabled('business', 'MONTHLY')}
                  featuresList={MONTHLY_FEATURES.business}
                  checkDiscountAllowed={checkDiscountAllowed}
                />
              </ComponentWithAnalytics>
            </Case>

            <Case condition={planTab == 'shop'}>
              <ComponentWithAnalytics event="visit_online_store">
                <CardPlan
                  title="500 генераций"
                  price={1000}
                  originalPrice={1000}
                  onButtonClick={withAnalyticsPacket('click_generations_500', '1')}
                  buttonText="Купить"
                  disableButton={!areSubscriptionExist}
                  useButtonWithTooltip
                  btnTooltipText="Оформите тариф для приобретения генераций"
                />
                <CardPlan
                  title="1000 генераций"
                  price={1500}
                  originalPrice={1500}
                  onButtonClick={withAnalyticsPacket('click_generations_1000', '2')}
                  buttonText="Купить"
                  disableButton={!areSubscriptionExist}
                  useButtonWithTooltip
                  btnTooltipText="Оформите тариф для приобретения генераций"
                />
                <CardPlan
                  title="2000 генераций"
                  price={3000}
                  originalPrice={3000}
                  onButtonClick={withAnalyticsPacket('click_generations_2000', '3')}
                  buttonText="Купить"
                  disableButton={!areSubscriptionExist}
                  useButtonWithTooltip
                  btnTooltipText="Оформите тариф для приобретения генераций"
                />
              </ComponentWithAnalytics>
            </Case>
          </Switch>
        </div>
      </div>
    </div>
  )
}

const ComponentWithAnalytics = (p: PropsWithChildren<{ event: string }>) => {
  useEffect(() => {
    if (p.event) {
      reachGoal(p.event)
    }
  }, [p.event])
  return p.children as any
}
