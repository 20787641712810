import { PropsWithChildren } from 'react'
import s from './GenBgAdvicePanel.module.css'

type GenBgAdvicePanelProps = {
  header?: string
}

export function GenBgAdvicePanel(props: PropsWithChildren<GenBgAdvicePanelProps>) {
  const { header, children } = props

  return (
    <div className={s.advice_panel}>
      {header && <div className={s.advice_panel__header}>{header}</div>}
      <p className={s.advice_panel__text}>
        Шаблон инфографики применен. Теперь вы можете его отредактировать или сразу перейти к
        генерации фона
      </p>

      <div className={s.advice_panel__body}>{children}</div>
    </div>
  )
}
