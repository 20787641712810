import cx from 'clsx'
import styles from './ChangePassword.module.css'
import registerStyles from '../../../auth/register.module.css'
import React, { useState } from 'react'
import { Button } from '../../Button'
import { backendUrl } from '../../../config/app'
import { useFullPageLoading } from '../../../contexts/FullPageLoadingContext'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check_circle.svg'
import { ReactComponent as ErrorSign } from './error_sign.svg'

const ChangePasswordSuccess = () => {
  return (
    <div className={cx(styles.ToastBlock, styles.SuccessBlock)}>
      <div className={styles.checkIcon}>
        <CheckIcon />
      </div>
      <div className={styles.SuccessText}>Пароль успешно изменен</div>
    </div>
  )
}
const ChangePasswordError = () => {
  return (
    <div className={cx(styles.ToastBlock, styles.ErrorBlock)}>
      <div className={styles.checkIcon}>
        <ErrorSign />
      </div>
      <div className={styles.SuccessText}>Что-то пошло не так, попробуйте позже</div>
    </div>
  )
}

export const ChangePassword = () => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const { setLoading, isLoading } = useFullPageLoading()
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [repeatError, setRepeatError] = useState<string | null>(null)
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null)
  const [isSuccess, setSuccess] = useState<boolean | null>(null)

  const validatePassword = () => {
    if (!password) {
      setPasswordError('Введите старый пароль')
      return false
    } else if (passwordError) {
      setPasswordError(null)
    }
    return true
  }

  const validateRepeat = () => {
    if (repeatPassword && newPassword && repeatPassword !== newPassword) {
      setRepeatError('Пароли не совпадают')
      return false
    } else if (repeatError) {
      setRepeatError(null)
    }
    return true
  }

  const validateNew = () => {
    if (newPassword.length < 8) {
      setNewPasswordError('Пароль должен состоять минимум из 8 символов')
      return false
    } else if (newPasswordError) {
      setNewPasswordError(null)
    }
    return validateRepeat()
  }

  const changePassword = async (e: React.FormEvent) => {
    setLoading(true)
    e.preventDefault()
    if (!validatePassword() || !validateRepeat() || !validateNew()) {
      setLoading(false)
      return
    }

    const res = await fetch(`${backendUrl()}api/change-password`, {
      method: 'POST',
      body: JSON.stringify({ newPassword, oldPassword: password }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    setSuccess(res.status === 403 ? null : res.status === 200)
    if (res.status === 403) setPasswordError('Неверный пароль')
    setLoading(false)
  }

  const buttonDisabled =
    !password || !newPassword || !repeatPassword || !!passwordError || !!repeatError

  return (
    <section className={styles.ChangePassword}>
      <div className={styles.PasswordInput}>
        <label className={registerStyles.inputLabel} htmlFor="password">
          Старый пароль
        </label>
        <input
          placeholder="Пароль"
          className={cx(
            registerStyles.input,
            'bodyM',
            { [styles.InputPensil]: !passwordError },
            { [registerStyles.inputError]: !!passwordError }
          )}
          type="password"
          id="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          onBlur={validatePassword}
          disabled={isLoading}
          required
        />
        {passwordError && (
          <p className={`paragraphS colorAccentPinkDark ${registerStyles.errorText}`}>
            {passwordError}
          </p>
        )}
      </div>
      <div className={styles.PasswordInput}>
        <label className={registerStyles.inputLabel} htmlFor="password">
          Новый пароль
        </label>
        <input
          placeholder="Новый пароль"
          className={cx(registerStyles.input, 'bodyM', { [styles.InputPensil]: !repeatError })}
          type="password"
          id="password"
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value)
          }}
          onBlur={validateNew}
          disabled={isLoading}
          required
        />
        {newPasswordError && (
          <p className={`paragraphS colorAccentPinkDark ${registerStyles.errorText}`}>
            {newPasswordError}
          </p>
        )}
      </div>
      <div className={styles.PasswordInput}>
        <label className={registerStyles.inputLabel} htmlFor="password">
          Повторите новый пароль
        </label>
        <input
          placeholder="Повторите новый пароль"
          className={cx(
            registerStyles.input,
            'bodyM',
            { [styles.InputPensil]: !repeatError },
            { [registerStyles.inputError]: !!repeatError }
          )}
          type="password"
          id="password"
          value={repeatPassword}
          onBlur={validateRepeat}
          onChange={(e) => {
            setRepeatPassword(e.target.value)
          }}
          disabled={isLoading}
          required
        />
        {repeatError && (
          <p className={`paragraphS colorAccentPinkDark ${registerStyles.errorText}`}>
            {repeatError}
          </p>
        )}
      </div>
      <div className={styles.SaveContainer}>
        <Button
          variation="primary"
          disabled={buttonDisabled}
          className={styles.SaveButton}
          onClick={changePassword}
        >
          Сохранить пароль
        </Button>
      </div>
      {isSuccess && <ChangePasswordSuccess />}
      {isSuccess === false && <ChangePasswordError />}
    </section>
  )
}
