import { AiService } from '../types/ai'

export class ImageResultStore {
  #store: Record<string, AiService>
  #currentSelected: AiService | 'unknown' = 'unknown'

  constructor() {
    this.#store = {}
  }

  storeUrl(imageUrl: string, srvType: AiService) {
    this.#store[imageUrl] = srvType
  }

  getAiService(imageUrl: string): AiService {
    return this.#store[imageUrl] ?? 'unknown'
  }

  setCurrentByImageUrl(url: string) {
    this.#currentSelected = this.getAiService(url)
  }

  getCurrent(): string {
    return this.#currentSelected
  }
}
