import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import styles from './register.module.css'
import { backendUrl } from '../config/app'
import { useFullPageLoading } from '../contexts/FullPageLoadingContext'
import { Button } from '../components/Button'
import { R_SIGN_IN_PAGE, R_THANKS_SIGN_UP } from '../router-constants'

const ForgotPassword: React.FC<{}> = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const { setLoading, isLoading } = useFullPageLoading()
  const navigate = useNavigate()

  const goSignIn = () => navigate(R_SIGN_IN_PAGE)
  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setLoading(true)
      const result: { data: { status: boolean; code?: number } } = await axios.post(
        `${backendUrl()}api/forgot-password`,
        { email: email }
      )
      setLoading(false)
      if (!result.data.status) {
        setError('Пользователь не найден')
        return
      }
      navigate(R_THANKS_SIGN_UP)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }
  return (
    <div className={styles.upload}>
      <div className={styles.content}>
        <div className={styles.forgotPasswordTitle}>
          <img src="/ArrowBackRounded.svg" className={styles.backButton} onClick={goSignIn} />
          <h2 className="colorTextBlackPrimary titleForgot">Восстановить пароль</h2>
        </div>
        <form className={styles.form} onSubmit={handleForgotPassword}>
          <div className={styles.inputContainer}>
            <input
              className={`${styles.input} bodyM ${error ? styles.inputError : ''}`}
              placeholder="mail@example.ru"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <span
              className="paragraphS colorAccentPinkDark"
              style={{ display: 'block', width: '100%', textAlign: 'center' }}
            >
              {error}
            </span>
          </div>
          <Button type="submit" disabled={isLoading} size="m">
            Получить пароль
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
