import projectsError from './projects-error.svg'
import s from './ProjectsError.module.css'
import { Button } from '../../Button'

export type ProjectsErrorProps = {
  onReload?: () => void
}

export function ProjectsError(props: ProjectsErrorProps) {
  const { onReload } = props

  return (
    <div className={s.NoProjectsContainer}>
      <div className={s.image}>
        <img src={projectsError} />
      </div>

      <div className={s.Content}>
        <div className={s.Header}>Что-то пошло не так</div>

        {onReload && (
          <div className={s.SubHeader}>
            <Button onClick={onReload} grow={false}>
              Обновить
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
