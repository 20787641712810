import { Button } from '../../Button'
import cx from 'clsx'
import s from './card-plan.module.css'
import { ReactComponent as CheckIcon } from './icon-check.svg'
import { ReactComponent as QuestionIcon } from '../question.svg'
import { ReactComponent as DisadvantageIcon } from '../remove_circle_outline.svg'

export type Feature = {
  label: string
  hint?: string
  disadvantage?: boolean
}

export type CardPlanProps = {
  title: string
  subTitle?: string
  price: number
  clientTypeTip?: string
  planId?: string
  /**
    Price use as original to show the discount/promocode is applied
  */
  originalPrice: number
  priceDescription?: string
  pricePostfix?: string
  onButtonClick: () => void
  buttonText: string
  highlight?: boolean
  disableButton?: boolean
  moneyFormatter?: (amount: number) => string
  featuresList?: Array<Feature>
  useButtonWithTooltip?: boolean
  btnTooltipText?: string
  /**
    Amount of amount discount in percent
  */
  discountAmount?: number

  checkDiscountAllowed?: (planId?: string) => boolean
}

export const formatRussianRubles = (amount: number) => {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  return formatter.format(amount)
}

export function CardPlan(props: CardPlanProps) {
  const {
    title,
    subTitle,
    price,
    clientTypeTip,
    pricePostfix,
    onButtonClick,
    buttonText,
    priceDescription,
    featuresList = [],
    disableButton = false,
    moneyFormatter = formatRussianRubles,
    highlight = false,
    useButtonWithTooltip,
    discountAmount,
    originalPrice,
    checkDiscountAllowed,
    planId,
  } = props

  const hasDiscount: boolean =
    !!(!!planId && checkDiscountAllowed && !!discountAmount) && checkDiscountAllowed(planId)

  return (
    <div className={cx(s.CardPlan, { [s.highlighted]: highlight })}>
      <div className={s.Header}>
        <div>{title}</div>
        {subTitle && <div className={s.SubHeader}>{subTitle}</div>}
      </div>

      {clientTypeTip && <div className={cx(s.clientTypeTip,'bodyS')}>
        { clientTypeTip }
      </div>}

      <div className={s.Price}>
        <div>
          {!hasDiscount && moneyFormatter(price)}
          {hasDiscount && (
            <div className={s.DiscountBlock}>
              <div className={s.DiscountBlockPrice}>
                <div className={s.strike}>{moneyFormatter(originalPrice)}</div>
                <div className={cx(s.DiscountPercentBubble, 'bodyS')}>-{discountAmount}%</div>
              </div>
              <div className={s.DiscountFinalPrice}>
                {moneyFormatter(price)}
                {pricePostfix && <span className={s.DiscountPricePostfix}>/ {pricePostfix}</span>}
              </div>
            </div>
          )}
          {!hasDiscount && pricePostfix && <span className={s.PricePostfix}>/ {pricePostfix}</span>}
        </div>

        {priceDescription && <div className={s.PriceDescription}>{priceDescription}</div>}
      </div>

      <div>
        {/* DON'T REMOVE */}
        {/* {useButtonWithTooltip && disableButton ? (
        <Button size='m' onClick={onButtonClick} disabled={disableButton}>
          <div className={s.buttonWithTooltip}>
            <div>{buttonText}</div>
            {btnTooltipText && (
              <div className={s.tooltip}>
                <QuestionWhiteIcon />
                <div className={s.tooltip_text}>{btnTooltipText}</div>
              </div>
            )}
          </div>

        </Button>
      ) : (<Button size='m' onClick={onButtonClick} disabled={disableButton}>{buttonText}</Button>)} */}
        <Button size="m" onClick={onButtonClick} disabled={disableButton}>
          {buttonText}
        </Button>
        {disableButton && useButtonWithTooltip && (
          <p className={s.tooltipFallback}>Оформите тариф для приобретения генераций</p>
        )}
      </div>

      {featuresList && featuresList.length > 0 && <div className={s.Divider} />}

      {featuresList.length > 0 && (
        <div>
          <div className={s.FeaturesList}>
            {featuresList.map((i, ix) => (
              <div key={ix} className={s.Feature}>
                <div className={cx(s.FeatureIcon)}>
                  {i.disadvantage ? <DisadvantageIcon/> : <CheckIcon />}
                </div>
                <div dangerouslySetInnerHTML={{ __html: i.label }} />
                {i.hint && (
                  <div className={s.tooltip}>
                    <QuestionIcon />
                    <div className={s.tooltip_text}>{i.hint}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
